import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { MaincontentComponent } from './maincontent/maincontent.component';
import { PolsComponent } from './pages/pols/pols.component';
import { TakepolsComponent } from './pages/takepols/takepols.component';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { TakesurveysComponent } from './pages/takesurveys/takesurveys.component';
import { SubmitassignmentsComponent } from './pages/submitassignments/submitassignments.component';
import { JoinconferenceComponent } from './pages/joinconference/joinconference.component';
import { LearningmaterialComponent } from './pages/learningmaterial/learningmaterial.component';
import { PostassessmentComponent } from './pages/postassessment/postassessment.component';
import { MyresultsComponent } from './pages/myresults/myresults.component';
import { StartexamComponent } from './pages/startexam/startexam.component';
import { TimetrackerComponent } from './pages/timetracker/timetracker.component';
import { AssignmentsComponent } from './pages/assignments/assignments.component';
import { AddassignmentComponent } from './pages/addassignment/addassignment.component';
import { EvaluateassignmentsComponent } from './pages/evaluateassignments/evaluateassignments.component';
import { EvaluateassessmentComponent } from './pages/evaluateassessment/evaluateassessment.component';
import { ExamresultComponent } from './pages/examresult/examresult.component';
import { CourcesessionsComponent } from './pages/courcesessions/courcesessions.component';
import { CreatepolComponent } from './pages/createpol/createpol.component';
import { CreatesurveyComponent } from './pages/createsurvey/createsurvey.component';
import { AddpolComponent } from './pages/addpol/addpol.component';
import { AddsurveyComponent } from './pages/addsurvey/addsurvey.component';
import { AddsurveyquestionComponent } from './pages/addsurveyquestion/addsurveyquestion.component';
import { CreateFAQsComponent } from './pages/create-faqs/create-faqs.component';
import { FeedbackquestionaireComponent } from './pages/feedbackquestionaire/feedbackquestionaire.component'
import { FeedbackResultsComponent } from './pages/feedback-results/feedback-results.component'
import { SmtpComponent } from './pages/smtp/smtp.component';
import { TaskmanagerComponent } from './pages/taskmanager/taskmanager.component';
import { NewsComponent } from './pages/news/news.component';
import { CourceCategoryComponent } from './pages/cource-category/cource-category.component';
import { CourceTypeComponent } from './pages/cource-type/cource-type.component';
import { CourcesComponent } from './pages/cources/cources.component';
import { ChaptersComponent } from './pages/chapters/chapters.component';
import { EnrollComponent } from './pages/enroll/enroll.component';
import { RegulationsComponent } from './pages/regulations/regulations.component';
import { AttainmentlevelComponent } from './pages/attainmentlevel/attainmentlevel.component';
import { RoomsComponent } from './pages/rooms/rooms.component';
import { AssignMaterialsComponent } from './pages/assign-materials/assign-materials.component';
import { ContentManagerComponent } from './pages/content-manager/content-manager.component';
import { AssessmentQuestionnaireComponent } from './pages/assessment-questionnaire/assessment-questionnaire.component';
import { ScheduleAssessmentComponent } from './pages/schedule-assessment/schedule-assessment.component';
import { MasterAssessmentComponent } from './pages/master-assessment/master-assessment.component';
import { AttendanceComponent } from './pges/attendance/attendance.component';
import { CourceAssignTrainerComponent } from './pges/cource-assign-trainer/cource-assign-trainer.component';
import { CourceScheduleComponent } from './pges/cource-schedule/cource-schedule.component';
import { AddCourceScheduleComponent } from './pges/add-cource-schedule/add-cource-schedule.component';
import { CourceAssignUsersComponent } from './pges/cource-assign-users/cource-assign-users.component';
import { MailBoxComponent } from './pges/mail-box/mail-box.component';
import { ForumComponent } from './pges/forum/forum.component';
import { ForumTopicsComponent } from './pges/forum-topics/forum-topics.component';
import { AddAsseementQuestioniareComponent } from './pages/add-asseement-questioniare/add-asseement-questioniare.component';
import { AddMasterAssessmentComponent } from './pages/add-master-assessment/add-master-assessment.component';
import { CourseshAssignUsersComponent } from './pages/coursesh-assign-users/coursesh-assign-users.component';
import { CourseshAssigntrainersComponent } from './pages/coursesh-assigntrainers/coursesh-assigntrainers.component';
import { CourseProgramOutcomeComponent } from './pages/course-program-outcome/course-program-outcome.component';
import { RoleComponent } from './pages/role/role.component';
import { TaskInRolesComponent } from './pages/task-in-roles/task-in-roles.component';
import { MyPostsComponent } from './pages/my-posts/my-posts.component';
import { AllBlogsComponent } from './pages/all-blogs/all-blogs.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { ViewPostComponent } from './pages/view-post/view-post.component';
import { Resolver } from './services/resolve.service';
import { UserRegistrationComponent } from './pages/user-registration/user-registration.component';
import { AddUserRegistrationComponent } from './pages/add-user-registration/add-user-registration.component';
import { EditUserRegistrationComponent } from './pages/edit-user-registration/edit-user-registration.component';
import { TenantRegistrationComponent } from './pages/tenant-registration/tenant-registration.component';
import { CreateTenantRegistrationComponent } from './pages/create-tenant-registration/create-tenant-registration.component';
import { ContentAuthoringComponent } from './pages/content-authoring/content-authoring.component';
import { BackupManagerComponent } from './pages/backup-manager/backup-manager.component';
import { OfflinePaymentComponent } from './pages/offline-payment/offline-payment.component';
import { BillingInformationComponent } from './pages/billing-information/billing-information.component';
import { SubjectComponent } from './pages/subject/subject.component';
import { PollResultsComponent } from './pages/poll-results/poll-results.component';
import { EventRequestComponent } from './pages/event-request/event-request.component';
import { DataDictionaryComponent } from './pages/data-dictionary/data-dictionary.component';
import { SubscriptionTaskComponent } from './pages/subscription-task/subscription-task.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { DetailedAssessmentComponent } from './pages/reports/detailed-assessment/detailed-assessment.component';
import { StudentInformationComponent } from './pages/reports/student-information/student-information.component';
import { CourseTrainersDetailsComponent } from './pages/reports/course-trainers-details/course-trainers-details.component';
import { CourseInformationReportComponent } from './pages/reports/course-information-report/course-information-report.component';
import { OnlineExamreportComponent } from './pages/reports/online-examreport/online-examreport.component';
import { OnlineCoursesReportComponent } from './pages/reports/online-courses-report/online-courses-report.component';
import { OnlineCoursesDetailsReportComponent } from './pages/reports/online-courses-details-report/online-courses-details-report.component';
import { UserReportComponent } from './pages/reports/user-report/user-report.component';
import { CoursewiseperformanceReportComponent } from './pages/reports/coursewiseperformance-report/coursewiseperformance-report.component';
import { AssessmentResultComponent } from './pages/assessment-result/assessment-result.component';
import { AttendanceDetailsReportComponent } from './pages/reports/attendance-details-report/attendance-details-report.component';
import { FolderComponent } from './pages/folder/folder.component';
import { UploadTemplateComponent } from './pages/upload-template/upload-template.component';
import { AcademicYearComponent } from './pages/academic-year/academic-year.component';
import { CurriculumComponent } from './pages/curriculum/curriculum.component';
import { FeeDescriptionComponent } from './pages/fee-description/fee-description.component';
import { FeeReceivableComponent } from './pages/fee-receivable/fee-receivable.component';
import { StudentFeereceivableComponent } from './pages/student-feereceivable/student-feereceivable.component';
import { EditFeesComponent } from './pages/edit-fees/edit-fees.component';
import { ComposeMailComponent } from './components/compose-mail/compose-mail.component';
import { ViewmailComponent } from './components/viewmail/viewmail.component';
import { FeesTypeComponent } from './pages/fees-type/fees-type.component';
import { SamvaadMeetingsComponent } from './pages/samvaad-meetings/samvaad-meetings.component';
import { LibraryManagementSystemComponent } from './pages/library-management-system/library-management-system.component';
import { LibraryBooksAllocationComponent } from './pages/library-books-allocation/library-books-allocation.component';
import { ListOfBooksComponent } from './pages/list-of-books/list-of-books.component';
import { BookAllocationReportComponent } from './pages/book-allocation-report/book-allocation-report.component';
import { BookAllocationViewReportComponent } from './pages/book-allocation-view-report/book-allocation-view-report.component';
import { ReturnDateComponent } from './pages/return-date/return-date.component';
import { HolidayNotificationComponent } from './pages/holiday-notification/holiday-notification.component';
import { DepartmentComponent } from './pages/department/department.component';
import { ViewDiscussionComponent } from './pages/view-discussion/view-discussion.component';
import { ViewNewsComponent } from './pages/view-news/view-news.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  
    children: [
      {
        path: 'dashboard',
        component: MaincontentComponent
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'poll',
        component: PolsComponent,
      },
      {
        path: 'takepoll',
        component: TakepolsComponent
      },
      {
        path: 'surveys',
        component: SurveysComponent
      },
      {
        path: 'takesurvey',
        component: TakesurveysComponent
      }, {
        path: 'submitassignment',
        component: SubmitassignmentsComponent
      }, {
        path: 'joinconference',
        component: JoinconferenceComponent
      }, {
        path: 'learningmaterial',
        component: LearningmaterialComponent
      }, {
        path: 'postassessment',
        component: PostassessmentComponent
      },
      {
        path: 'myresults',
        component: MyresultsComponent
      },
      {
        path: 'takeExam',
        component: StartexamComponent
      },
       {
        path: 'timeTracker',
        component: TimetrackerComponent
      }, 
      {
        path: 'assignments',
        component: AssignmentsComponent
      },
      {
        path: 'addAssignments',
        component: AddassignmentComponent
      },
       {
        path: 'evaluateAssignments',
        component: EvaluateassignmentsComponent
      },
      {
        path: 'evaluateAssessment',
        component: EvaluateassessmentComponent
      },
      {
        path: 'examResult',
        component: ExamresultComponent
      },
      {
        path: "courseSessions",
        component: CourcesessionsComponent
      },
      {
        path: "createPolls",
        component: CreatepolComponent
      },
      {
        path: "createSurvey",
        component: CreatesurveyComponent
      },
       {
        path: 'addPoll',
        component: AddpolComponent
      },
      {
        path: 'addSurvey',
        component: AddsurveyComponent
      },
       {
        path: 'surveyQuestion',
        component: AddsurveyquestionComponent
      },
      {
        path: "createFAQs",
        component: CreateFAQsComponent

      },
      {
        path: 'feedbackQuestionnaire',
        component: FeedbackquestionaireComponent
      },
      {
        path: 'feedbackResults',
        component: FeedbackResultsComponent
      },
      {
        path: 'smtp',
        component: SmtpComponent
      },
      {
        path: 'taskmanager',
        component: TaskmanagerComponent
      },
      {
        path: 'news',
        component: NewsComponent
      }, {
        path: 'courseCategory',
        component: CourceCategoryComponent
      },
       {
        path: 'courseType',
        component: CourceTypeComponent
      },
      {
        path:"courses",
        component:CourcesComponent
      },
      {
        path:'course-assignTrainer',
        component:CourceAssignTrainerComponent,
      },
      {
        path:'course-programOutcome',
        component:CourseProgramOutcomeComponent
      },
      {
        path:'chapters',
        component:ChaptersComponent
      },
      {
        path:'enroll',
        component:EnrollComponent
      },
      {
        path:"regulations",
        component:RegulationsComponent
      },
      {
        path:'attainmentlevel',
        component:AttainmentlevelComponent
      },{
        path:'rooms',
        component:RoomsComponent
      },{
        path:'configurematerials',
        component:AssignMaterialsComponent
      },
      {
        path:'contentmanager',
        component:ContentManagerComponent
      },
      {
        path:'assessmentQuestionnaire',
        component:AssessmentQuestionnaireComponent
      },
      {
        path:'asssessemnt/:id',
        component:AddAsseementQuestioniareComponent
      },
      {
        path:'scheduleAssessment',
        component:ScheduleAssessmentComponent
      },
      {
        path:'masterAssessment',
        component:MasterAssessmentComponent
      },
      {
        path:'masterAssessment/:id',
        component:AddMasterAssessmentComponent
      },
      {
        path:'attendance',
        component:AttendanceComponent
      },
      
      {
        path:'courseSchedule',
        component:CourceScheduleComponent
      },
      {
        path:'courseSchedule/:id',
        component:AddCourceScheduleComponent
      },
      {
        path:'courseSchedule-AssignUser',
        component:CourseshAssignUsersComponent
      },
      {
        path:'courseSchedule-AssignTrainer',
        component:CourseshAssigntrainersComponent
      },
      {
        path:'mailBox',
        component:MailBoxComponent
      },
      {
        path:'forums',
        component:ForumComponent
      },
      {
        path:'forumTopics',
        component:ForumTopicsComponent
      },{
        path:'role',
        component:RoleComponent
      },
      {
        path:'taskInRole',
        component:TaskInRolesComponent
      },
      {
       path:'myPosts',
       component:MyPostsComponent
      },
      {
        path:'allBlogs',
        component:AllBlogsComponent
      },
      {
        path:'blogs',
        component:BlogsComponent
      },{
        path:'viewPost',
        component:ViewPostComponent
      },{
        path:'userRegistration',
        component:UserRegistrationComponent
      },
      {
        path:'addUserRegistration',
        component:AddUserRegistrationComponent
      },
      {
        path:'editUserRegistration',
        component:EditUserRegistrationComponent
      },
      {
        path:'tenantRegistration',
        component:TenantRegistrationComponent
      },
      {
        path:'tenantRegistration/:id',
        component:CreateTenantRegistrationComponent
      },
      {
        path:'contentAuthoring',
        component:ContentAuthoringComponent
      },
      {
        path:'backupManager',
        component:BackupManagerComponent
      },
      {
        path:'offlinePayment',
        component:OfflinePaymentComponent
      },
      {
        path:'billingInformation',
        component:BillingInformationComponent
      },
      {
        path:'subject',
        component:SubjectComponent
      },
      {
        path:'pollResults',
        component:PollResultsComponent
      },
      {
        path:'eventRequest',
        component:EventRequestComponent
      },
      {
        path:'dataDictionary',
        component:DataDictionaryComponent
      },
      {
       path:'subscriptionTasks',
       component:SubscriptionTaskComponent
      },
      {
        path:'locations',
        component:LocationsComponent
      },
      {
        path:'DetailedAssessment',
        component:DetailedAssessmentComponent
      },
      {
        path:'studentInformation',
        component:StudentInformationComponent
      },
      {
          path:'courseTrainersReport',
          component:CourseTrainersDetailsComponent
      },
      {
        path:'courseInformationReport',
        component:CourseInformationReportComponent
      },
      {
        path:'onlineExamReport',
        component:OnlineExamreportComponent
      },
      {
        path:'onlineCoursesReport',
        component:OnlineCoursesReportComponent
      },
      {
        path:'onlineCoursesDeatilsReport',
        component:OnlineCoursesDetailsReportComponent
      },
      {
        path:'userReport',
        component:UserReportComponent
      },
      {
        path:'courseWisePerformance',
        component:CoursewiseperformanceReportComponent
      },
      {
        path:'assessmentResult',
        component:AssessmentResultComponent
      },
      {
        path:"attendanceReport",
        component:AttendanceDetailsReportComponent
      },
      {
        path: 'foldermanagemnet',
        component: FolderComponent
      },
      {
        path: 'uploadTemplate',
        component: UploadTemplateComponent
      },
      {
        path: 'academic-year',
        component: AcademicYearComponent
      },
      {
        path: 'curriculum',
        component: CurriculumComponent
      },
      {
        path: 'fee-description',
        component: FeeDescriptionComponent
      },
      {
        path: 'fee-receivable',
        component: FeeReceivableComponent
      },
      {
        path: 'student-feereceivable',
        component: StudentFeereceivableComponent
      },
      {
        path: 'editFees',
        component: EditFeesComponent
      },
      {
        path: 'editFees/:id',
        component: EditFeesComponent
      },
      {
        path: 'fees-type',
        component: FeesTypeComponent
      },
      {
        path: 'compose-mail',
        component: ComposeMailComponent
      },
      {
        path: 'viewmail',
        component: ViewmailComponent 
      },
      {
        path: 'meetings',
        component: SamvaadMeetingsComponent
      },
      {
        path:'libraryManagementSystem',
        component:LibraryManagementSystemComponent
      },
      {
        path:'department',
        component:DepartmentComponent
      },
      {
        path:'libraryBooksAllocated',
        component:LibraryBooksAllocationComponent
      },
      {
        path:'listofbooks',
        component:ListOfBooksComponent

      },
      {
        path: 'bookAllocation',
        component: BookAllocationReportComponent
      },
      {
        path: 'viewReport',
        component: BookAllocationViewReportComponent
      },
      {
        path:"returnDate",
        component:ReturnDateComponent
      },
      {
        path:'holidayNotification',
        component:HolidayNotificationComponent
      },

      {
        path:'viewNews/:id',
        component:ViewNewsComponent
      },
      {
        path: 'viewNews',
        component: ViewNewsComponent
      },
      {
        path:'viewDiscussion/:id',
        component:ViewDiscussionComponent
      },
      {
        path: 'viewDiscussion',
        component: ViewDiscussionComponent
      },


    ]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'Home/dashboard',
    redirectTo: 'home',
  },
  {
    path: '**',
    redirectTo: 'home'
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// resolve:{menu:Resolver},
