<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Content Authoring</h5>
                        </div>
                        <div class="card-body">
                            <div class="form-group ">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="sel1">Tenant Type : </label>
                                        <select [(ngModel)]='tId ' (change)="getCourses()"
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                            <option value="" selected>select</option>
                                            <option *ngFor='let item of tenants' [value]='item.TNT_CODE'>
                                                {{item.TNT_NAME}}</option>



                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">Grade Name : </label>
                                        <select [(ngModel)]='data.CourseId ' 
                                            class="form-control form-control-sm shadow-sm rounded-lg"
                                            (change)="courseChange()">
                                            <option value="" selected>select</option>
                                            <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                                {{course.COURSE_NAME}}
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">Material  Type : </label>
                                        <select [(ngModel)]='data.MaterialType ' (change)="materialChange()"
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                            <option value="" [selected]="true">select</option>
                                            <option>Uploaded Material</option>
                                            <option>Webinar Info</option>
                                            <option>E-Learning Material</option>
                                            <option >External Links</option>

                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">Material  Type : </label>
                                         <input (change)='materialChange()' class="form-control" [(ngModel)]='archive' type="checkbox">
                                    </div>
                                </div>


                            </div>

                            <div *ngIf="table.length">
                                <table id="patreggraph" datatable
                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small" style="width:100%">
                                    <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                        <tr>
                                            <th> 
                                                Upload Path </th>
                                            <th>
                                                Uploaded By
                                            </th>
                                            <th> 	Uploaded Date </th>

                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table">
                                            <td>{{item.MATERIAL_PATH}}</td>
                                            <td>{{item.FIRSTNAME}}</td>

                                            <td>{{item.MATERIAL_CREATED_DATE}}</td>
                                            <td class=" text-center" *ngIf='!item.MATERIAL_ISARCHIVE1'>
                                                <i class="fa fa-archive icon-fa" (click)=actin(item,true)>
                                                  <span class="span">Archive</span>
                                                </i>
                                            </td>
                                            <td  class=" text-center" *ngIf='item.MATERIAL_ISARCHIVE1'><i (click)="undo(item,false)" class="fa fa-undo  icon-fa">
                                                <span class="span">Rollback</span>
                                            </i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-center" *ngIf="!table.length">
                                No Records to display
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>