<div class="default_class">
    <div class="content-wrapper p-0">

        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">



                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Assign Materials</h5>
                        </div>

                        <div class="card-body">
                            <div class="form-group " >
                                <div class="row">
                                    <div class="col-md-3 ">
                                        <label for="sel1">	Grade Name <span class="text-danger">*</span>: </label>
                                        <select [(ngModel)]='courceId '
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="courseChange()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                                {{course.COURSE_NAME}}
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-3 ">
                                        <label for="sel1">	Grade Schedule<span class="text-danger">*</span>: </label>
                                        <select [(ngModel)]='sheduleId '
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="sheduleChange()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let shedule of shedules" [value]='shedule.COURSESHD_ID'>
                                                {{shedule.COURSESHD_NAME}}
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-3 ">
                                        <label for="sel1">	Chapter Name<span class="text-danger">*</span>: </label>
                                        <select [(ngModel)]='chapterId '
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="chapterChange()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let chapter of chapters" [value]='chapter.CHAPTER_ID'>
                                                {{chapter.CHAPTER_NAME}}
                                            </option>

                                        </select>
                                    </div>
                                    
                                </div>


                            </div>

                            <div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="box_cnt">
                                           <div class="pointer" (click)="checkRight($event,item,table)"  *ngFor="let item of table" [ngClass]='item.class'>
                                             {{item.MATERIAL_NAME}}
                                           </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1">

                                      <div>
                                       <div>  <button class="btn btn-outline-dark" (click)="shiftLeft()" [disabled]='enableLeft'><i class="mdi mdi-arrow-left"></i></button></div> 
                                       <div> <button class="btn btn-outline-dark" (click)="shiftRight()"  [disabled]='enableRight'> <i class="mdi mdi-arrow-right"></i></button></div> 
                                      <div>   <button class="btn btn-outline-dark" (click)="shiftAllLeft()" [disabled]='!table1.length'><i class="mdi mdi-skip-previous"></i></button></div> 
                                         <div> <button class="btn btn-outline-dark" (click)="shiftAllRight()" [disabled]='!table.length'> <i class="mdi mdi-skip-next"></i></button></div> 
                                      </div>

                                    </div>
                                    <div class="col-md-5">
                                        <div class="box_cnt">
                                            <div class="pointer" (click)="checkRight($event,item,table1)" *ngFor="let item of table1" [ngClass]='item.class'>
                                                {{item.MATERIAL_NAME}}
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="form-group eligible_criteria " >
                                <div class="form-group row">
                                    <label class="col-md-1"></label>
                                    <label class="col-md-3 control-label">
                                        Eligible Criteria </label>
                                    <div class="col-md-6 inputGroupContainer">
                                        <div class="radio" >  <strong>:</strong> &nbsp;
                                            <div class="form-check-inline">
                                                <label class="form-check-label">
                                                  <input value='0' [(ngModel)]='dataObj.Type'  type="radio" class="form-check-input" name="QuestionPerPage">Assessment     
                                                </label>
                                              </div>
                                              <div class="form-check-inline">
                                                <label class="form-check-label">
                                                  <input value='1' type="radio" [(ngModel)]='dataObj.Type'  class="form-check-input" name="QuestionPerPage">Study Duration
 
                                                </label>
                                              </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="dataObj.Type&&dataObj.Type=='1'">
                                    <label class="col-md-1"></label>
                                    <label class="col-md-3 control-label">
                                        Time Duration </label>
                                    <div class="col-md-6 inputGroupContainer">
                                        <div class="radio" >  <strong>:</strong> &nbsp;
                                            <div class="form-check-inline">
                                               <input type="text" [(ngModel)]='dataObj.Value' ><span>(hrs)</span>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                              
                            </div> -->
                           
                            <div class="text-center">
                                <button type="button" (click)="submit()" class="btn btn-primary">Submit</button> &nbsp;
                                <button type="button" class="btn btn-secondary" >cancel</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>