import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';
import { CommonService } from 'src/app/services/common.service';
import { forkJoin } from 'rxjs';
import { dataDictionary } from 'src/app/dataDictionary';
@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  educationTypes: Array<any> = [];
  educationModes: Array<any> = [];
  EducationName: Array<any> = [];
  educationNames: Array<any> = [];
  // Institutes: Array<any> = [];
  index: number;
  educationData: Array<any> = [];
 

  constructor(private fb: FormBuilder, private CommonService: CommonService) {
    this.getAll()
  }
  ngOnInit(): void {
    this.myForm = this.fb.group({
      EducationType: ['', Validators.required],
      EducationMode: ['', Validators.required],
      EducationName: ['', Validators.required],
      Class: ['', Validators.required],
      SchoolName: ['', Validators.required],
      YearOfPassing: ['', Validators.required]
    
 
    });
    // this.getEducationGroup();
    this.parent.childs['EducationTypeList'] = this
    console.log(this.parent.childs['EducationTypeList'] = this)
  }
  ngOnChanges() {
    if (Object.keys(this.editData).length && !this.changed) {
      this.changed = true;
       this.educationData = this.editData['Education'].slice(0);
      this.dataTransform(this.editData['Education'].slice(0));
    }
  }
  
  getAll() {
    let educationType = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.EducationType });//Eduction Type
    let educationMode = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.EducationMode });//Eduction Mode
    let educationNames = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.EducationName });//Eduction Name

    forkJoin([educationType, educationMode, educationNames]).subscribe((res) => {
      [this.educationTypes, this.educationModes, this.EducationName] = [...res];
    });
  }





  
  dataTransform(data: Array<any>) {

    data.map(item => {
      let obj = {
        type: item['type'],
        EducationType: item['EducationType'],
        EducationMode: item['EducationMode'],
        EducationName: item['EducationName'],
        Class:item['SPECIALIZATION'],
        SchoolName: item['UNIVERSITYINSTITUTE'],
        YearOfPassing: item['YearOfPassing'],
      }
      this.table.push(obj)
    });
    // this.addIds()

  }

  getEducationGroup() {

    this.parent.CommonService.postCall('GetEducationGroup', {}).subscribe(
      (res: any) => {
        if (Object.keys(res).length) {
          [this.educationTypes, this.educationModes] = [res.EducatonType, res.EducationMode]
        }
      }
    )
  }
  add() {
    this.isEdit = false;
  }
  edit(data, i) {
    console.log(data)
    this.index = i;
    this.isEdit = true;
    this.editData = data;
    this.assignDataForm(i);
  }
  assignDataForm(i){
    let data=this.educationData[i];
    let ctrls=this.myForm.controls;
    Object.keys(ctrls).map((formControlName:string)=>{
      let control:AbstractControl=ctrls[formControlName];
      control.setValue(data[formControlName]);
    })
    console.log('edit form value',this.myForm.value);
  }

  close() {
    this.myForm.reset()
  }
  onSubmit(form: FormGroup) {
    let value = form.value;
    if (this.isEdit) {
      if (this.editData.TYPE == 'edit') {
        value.TYPE = 'update'
      } else {
        value.TYPE = 'insert'
      }
      let data = Object.assign({}, value);
      this.table[this.index] = data;
      this.educationData.push(data);
      console.log(this.educationData);
    } else {
      value.TYPE = 'insert'
      let data = Object.assign({}, value);
      this.table.push(data);
      console.log(this.table)
      this.educationData.push(data);
    }
    // this.setIds();
    document.getElementById('md_close_education').click();
    this.parent.toastr.success('Updated successfully')
  }
//   setIds(){
//     this.table.map(item => {
//       let educationTypes =item.EducationType;
//       let jFind = this.educationTypes.findIndex(x => x.DICTIONARYID == +educationTypes);
//       if (jFind > -1) {
//         item.EducationType = this.educationTypes[jFind].DICTIONARYNAME
//       }
//       let educationModes = item.EducationMode;
//       let lFind = this.educationModes.findIndex(x => x.DICTIONARYID == +educationModes);
//       if (lFind > -1) {
//         item.EducationMode = this.educationModes[lFind].DICTIONARYNAME
//       }
//       let EducationName = item.EducationName;
//       let pFind = this.EducationName.findIndex(x => x.DICTIONARYID == +EducationName);
//       if (pFind > -1) {
//         item.EducationName = this.EducationName[pFind].DICTIONARYNAME
//       }
//     })
//     // this.languageFormatData(this.table.slice(0));
// }



  modeChange(value) {
    this.parent.activeSpinner();
    this.parent.CommonService.postCall('GetEducationName', { EducationModeId: value }).subscribe(
      (res: any) => {
        this.educationNames = res;
        this.parent.deactivateSpinner()
      }, err => {
        this.parent.deactivateSpinner()
      }
    )
  }
  //8499823644
}
