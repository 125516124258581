import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { dataDictionary } from 'src/app/dataDictionary';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css']
})
export class LanguagesComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  index: number;
  languages: Array<any> = [];
  levels: Array<any> = [];
  lanuageData: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      Language: ['', Validators.required],
      ProficiencyLevel: ['', Validators.required],
      ReadL: [''],
      WriteL: [''],
      SpeakL: [''],
    });
    this.getAll()
    this.parent.childs['LanguagesList'] = this
  }
  ngOnChanges() {
    if (Object.keys(this.editData).length && !this.changed) {
      this.changed = true;
      this.dataTransform(this.editData['Languages'].slice(0));
    }
  }

  dataTransform(data: Array<any>) {

    data.map(item => {
      let obj = {
        'LanguageID': item['LanguageID'],
        type: item['type'],
        Language: item['Language'],
        // LanguageName: item['Language'],
        // ProficiencyLevelName: item['ProficiencyLevel'],
        ProficiencyLevel:item['ProficiencyLevel'],
        ReadL: item['ReadL'],
        WriteL: item['WriteL'],
        SpeakL: item['SpeakL'],
      }
      this.table.push(obj)
    });
     this.addIds()

  }
  getAll() {
    let language = this.parent.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.Language});//GetLanguage
    let level = this.parent.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.ProficiencyLevel});//GetProficiencyLevel
    forkJoin([language, level,]).subscribe((res) => {
      [this.languages, this.levels,] = [...res];
       this.addIds()
    })

  }
  add() {
    this.isEdit = false;
  }
  edit(data, i) {
    this.index = i;
    this.isEdit = true;
    this.editData = data;
    this.assignDataForm(i);
  }
  close() {
    this.myForm.reset()
  }
  onSubmit(form: FormGroup) {
    let value = form.value;
    if (this.isEdit) {
      if (this.editData.type == 'edit') {
        value.type = 'update'
      } else {
        value.type = 'insert'
      }
      let data = Object.assign({}, value);
      // this.table[this.index] = data;
      data['LanguageID'] = this.editData['LanguageID']
      this.createAndUpdate(data, false);
    } else {
      value.type = 'insert'
      let data = Object.assign({}, value);
      // this.table.push(data);
      this.createAndUpdate(data, true)
    }
    document.getElementById('md_close_lan').click();
  }
  languageFormatData(data: Array<any>) {
     this.lanuageData = data.slice(0);
   }
  addIds() {
    // this.table.map(item => {
    //   let language = item.LanguageName;
    //   let lFind = this.languages.findIndex(x => x.DICTIONARYNAME == language);
    //   if (lFind > -1) {
    //     item.Language = this.languages[lFind].DICTIONARYID
    //   }
    //   let ProficiencyLevelName = item.ProficiencyLevelName;
    //   let pFind = this.levels.findIndex(x => x.DICTIONARYNAME == ProficiencyLevelName);
    //   if (pFind > -1) {
    //     item.ProficiencyLevel = this.levels[pFind].DICTIONARYID
    //   }
    // })
     this.languageFormatData(this.table.slice(0));
  }
  createAndUpdate(data, type) {
    let i = this.index;
    // let language = data.Language;
    // let lFind = this.languages.findIndex(x => x.DICTIONARYID == language);
    // if (lFind > -1) {
    //   data.LanguageName = this.languages[lFind].DICTIONARYNAME //DICTIONARYNAME
    // }
    // let ProficiencyLevelName = data.ProficiencyLevel;
    // let pFind = this.levels.findIndex(x => x.DICTIONARYID == ProficiencyLevelName);
    // if (pFind > -1) {
    //   data.ProficiencyLevelName = this.levels[pFind].DICTIONARYNAME
    // }
    if (type) {
      this.table.push(data);
      let obj = Object.assign({}, data);
      this.lanuageData.push(obj);
      this.parent.toastr.success('Added successfully')
    } else {
      this.table[i] = data;
      let obj = Object.assign({}, data);
      this.lanuageData[i] = obj
      this.parent.toastr.success('Updated successfully')
    }
  }
  assignDataForm(i) {
    let data = this.lanuageData[i];
    let ctrls = this.myForm.controls;
    Object.keys(ctrls).map((formControlName: string) => {
      let control: AbstractControl = ctrls[formControlName];
      control.setValue(data[formControlName]);
    })
  }
  check(item) {
    if (item.type == 'delete') {
      return true
    }
    else {
      return false
    }
  }
}