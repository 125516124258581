<div class="default_class">
    <div class="content-wrapper p-0">

        <div class="container-fluid">

            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">



                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0"> Grade Sessions</h5>
                        </div>

                        <div class="card-body">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="sel1">Grade Name : </label>
                                        <select [(ngModel)]='courseId ' [disabled]='isActive'
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="courseChange()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                                {{course.COURSE_NAME}}
                                            </option>

                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">Grade Schedule :</label>
                                        <select [(ngModel)]='scheduleId ' [disabled]='isActive'
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                            (change)="schedulChange()">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let schedul of schedules" [value]='schedul.COURSESHD_ID'>
                                                {{schedul.COURSESHD_NAME}}</option>

                                        </select>


                                    </div>
                                   
                                </div>


                            </div>


                            <div *ngIf="table.length" >
                                <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                    class="table table-striped table-bordered mt-2 pt-2 table-sm small"
                                    style="width:100%">
                                    <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                        <tr>
                                          <th>Date</th>
                                          <th>Chapters</th>
                                          <th>Teachers</th>
                                          <th>Offline/Online</th>
                                          
                                          <th>VC Request</th>
                                          <th>Lesson Plan</th>
                                          <th>Completed / Not Completed</th>
                                              
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table" >
                                            <td>{{item.CS_DATE|date:'dd-MM-yyyy'}}</td>
                                             <td>
                                                <select [(ngModel)]='item.CS_CHAPTER_ID'
                                                class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                                (change)="chapterChange()">
                                                <option value="0" [selected]="true">select</option>
                                                <option *ngFor="let chapter of chapters" [value]='chapter.CHAPTER_ID'>
                                                    {{chapter.CHAPTER_NAME}}
                                                </option>
    
                                            </select>
                                             </td>
                                             <td>
                                                 <select   class="form-control form-control-sm shadow-sm rounded-lg" [(ngModel)]='item.CS_TRAINER_ID' >
                                                     <option value=null selected >Select</option>
                                                     <option *ngFor='let item of trainers' [value]='item.COURSE_TRAINER_ID' >{{item.USER_FIRSTNAME}}</option>
                                                 </select>
                                             </td>

                                             <!-- //CHAPTER_NAME -->
                                             <td>
                                                <div class="radio" >
                                                    <label><input  type="radio" [value]='1' [(ngModel)]="item.CS_ONLINE_OFFLINE"
                                                            name={{item.CS_ID}}a>
                                                            Offline</label>
                                                    <label><input  type="radio" [value]='2' [(ngModel)]="item.CS_ONLINE_OFFLINE"
                                                            name={{item.CS_ID}}a>
                                                         Online</label>
                                                </div>
                                             </td>
                                             <td class="pointer">
                                                 <label (click)="riseEvent(item)" data-toggle="modal" data-target="#myModal1" *ngIf='item.CS_ONLINE_OFFLINE==2'>vc</label>
                                             </td>
                                             <td class="pointer">
                                                 <span (click)="viewPlan(item)" data-toggle="modal" data-target="#myModal">View Plan</span>
                                             </td>
                                             <td>
                                                <div class="radio" >
                                                    <label><input  type="radio" [value]='1' [(ngModel)]="item.CS_SCHEDULE_DELAY"
                                                            name={{item.CS_ID}}>
                                                            Completed</label>
                                                    <label><input  type="radio" [value]='2' [(ngModel)]="item.CS_SCHEDULE_DELAY"
                                                            name={{item.CS_ID}}>
                                                         Not   Completed</label>
                                                </div>
                                             </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="text-center">
                                    <button type="button" class="btn btn-primary" (click)="submit()" >Submit</button>
                                    &nbsp;  <button class="btn btn-danger" (click)='back()' > Back</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4  class="modal-title"> Lesson Plan</h4>
                
                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row" *ngIf='false'> 
                            <label class="col-md-4 control-label line_2 rem_1">
                                Course Schedule </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <label > board sc</label>
                                    <!-- <input placeholder="" disabled class="form-control" formControlName="courseSh"> -->
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Title <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control"
                                        formControlName="CSS_TITLE">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Description <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea placeholder="" class="form-control"
                                        formControlName="CSS_DESCRIPTION"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Activities <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control"
                                        formControlName="CSS_ACTIVITIES">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Reference <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control"
                                        formControlName="CSS_REFERENCE">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button"  class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
              
                <button type="button" class="btn btn-danger" id='md_close' (click)="close()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal1" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4  class="modal-title"> Add Event Request</h4>
                
                <button type="button" (click)="eventclose()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="eventMyForm" (ngSubmit)="eventOnSubmit(eventMyForm)">
                    <fieldset>   
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Title  <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input formControlName='APPOINTMENT_NAME' class="form-control form-control-sm shadow-sm rounded-lg"  >
                                    
                                </div>
                            </div>
                        </div>                     
                        <div class="form-group row">
                            <label class="col-md-4 control-label">	Grade Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select  disabled [(ngModel)]='courseId' (change)="courseChange()" class="form-control form-control-sm shadow-sm rounded-lg" formControlName="COURSE_ID"  >
                                        <option  value="" [selected]="true">select</option>
                                        <option   *ngFor="let course of courses" [value]='course.COURSE_ID'>{{course.COURSE_NAME}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>
                     
                        <div class="form-group row">
                            <label class="col-md-4 control-label">			Grade Schedule </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select disabled [(ngModel)]='scheduleId '    class="form-control form-control-sm shadow-sm rounded-lg" formControlName="COURSESCHEDULE_ID">
                                        <option  value="" [selected]="true">select</option>
                                        <option   *ngFor="let schedule of schedules" [value]='schedule.COURSESHD_ID'>{{schedule.COURSESHD_NAME}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                               Date <span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group" > 
                                    <input type="date" formControlName='APPOINTMENT_DATE' [ngModel] ="Date| date:'yyyy-MM-dd'" placeholder="" class="form-control"></div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Start Time <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> 
                                    <input type="time" [(ngModel)]="startTime" formControlName='APPOINTMENT_STARTTIME' placeholder="" class="form-control"></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                End Time  <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> 
                                    <input type="time" (change)="timeChange($event.target.value)" formControlName='APPOINTMENT_ENDTIME' placeholder="" class="form-control"></div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label">Comments  <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea formControlName='APPOINTMENT_DESCRIPTION' class="form-control form-control-sm shadow-sm rounded-lg"  >
                                    </textarea>
                                </div>
                            </div>
                        </div>

                      
                      
                      
                       
                    </fieldset>
                </form>
                
            </div>
            <div class="modal-footer">
                <button type="button"  class="btn btn-success" (click)="eventOnSubmit(eventMyForm)" [disabled]="eventMyForm.invalid">Save</button> 
                <!-- <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(eventMyForm)" [disabled]="myForm.invalid">Update</button>  -->
                <button
                    type="button" class="btn btn-danger" id="md_close1"  (click)="close()" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>