<div>
    <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="add()" data-toggle="modal"
            data-target="#myModal_prev">Add</button>

    </div>
    <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
        <thead>
            <tr>
                <th>
                    Student Name</th>
                <th>Previous school Name</th>
                <th>Grade </th>
                <th>DurationFrom</th>
                <th>DurationTo</th>
                <th> Pass mark Percentage</th>
                <th> Transfer of Ceritificate</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let item of table;let i=index'>
                <td>{{item.StudentName}}</td>
                <td>{{item.PreviousSchoolName}}</td>
                <td>{{item.Grade}}</td>
                <td>{{item.DurationFrom}}</td>
                <td>{{item.DurationTo}}</td>
                <td>{{item.PassMarkPer}}</td>
                <td>{{item.TransferCertificate}}</td>
                <td class=" text-center"><input type="image" src="./../../../assets/images/sprites/edit.svg"
                        class="edit_icon text-center" data-toggle="modal" data-target="#myModal_prev"
                        (click)="edit(item,i)"> </td>
            </tr>
        </tbody>
    </table>
    <p class="text-center" *ngIf='!table.length'>No Records</p>
</div>

<div class="modal" id="myModal_prev" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 *ngIf="!isEdit" class="modal-title"> Add Previous School Experience </h4>
                <h4 *ngIf="isEdit" class="modal-title"> Edit Previous School Experience</h4>
                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">
                                Student Name<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Enter Student Name " formControlName='StudentName' type="text"
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                </div>
                                <div *ngIf="myForm.get('StudentName').touched">
                                    <span class="text-danger" *ngIf="myForm.get('StudentName').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">
                                Previous School Name<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Enter Previous School Name "
                                        formControlName='PreviousSchoolName' type="text"
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                </div>
                                <div *ngIf="myForm.get('PreviousSchoolName').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('PreviousSchoolName').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">
                              Grade<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Grade "
                                        formControlName='Grade' type="text"
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                </div>
                                <div *ngIf="myForm.get('Grade').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('Grade').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Duration From <span class="text-danger">*</span></label>
                            <div class="col-md-3 inputGroupContainer">
                                <div class="input-group">
                                    <input type="date" formControlName='DurationFrom'
                                        class="form-control form-control-sm shadow-sm rounded-lg">


                                </div>
                        <div *ngIf="myForm.get('DurationFrom').touched">
                            <span class="text-danger"
                                *ngIf="myForm.get('DurationFrom').hasError('required')">
                                This field is required.
                            </span>

                        </div>
                    </div>
                    <label class="col-md-0 control-label">To</label>
                    <div class="col-md-3 inputGroupContainer">
                        <div class="input-group">
                            <input type="date" formControlName='DurationTo'
                                class="form-control form-control-sm shadow-sm rounded-lg">


                        </div>
                        <div *ngIf="myForm.get('DurationTo').touched">
                            <span class="text-danger" *ngIf="myForm.get('DurationTo').hasError('required')">
                                This field is required.
                            </span>

                        </div>
                    </div>
                </div>
                       
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Pass Mark Percentage <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Enter Pass Mark Percentage" class="form-control"
                                        formControlName="PassMarkPer">

                                </div>
                                <div *ngIf="myForm.get('PassMarkPer').touched">
                                    <span class="text-danger" *ngIf="myForm.get('PassMarkPer').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Transfer Ceritificate <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea placeholder="Enter Transfer Ceritificate" class="form-control"
                                        formControlName="TransferCertificate">
                                    </textarea>
                                </div>
                                <div *ngIf="myForm.get('TransferCertificate').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('TransferCertificate').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id="md_close_Prev" (click)="close()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>