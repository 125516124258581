



    <div class="default_class">
        <div class="content-wrapper p-0">
      
      <div class="container-fluid">
      
        <div class="row">
            <div class="col-xl-12">
                <div class="card rounded-lg shadow-lg" >
      
      
               
                <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                  <h5 class="mb-0">Submit Assignment</h5> 
                </div>
      
                <div class="card-body">

                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-3">
                                <label for="sel1">Grade Name : </label>
                                <select [(ngModel)]='courceId ' id="sel1" (change)="courceChange()" class="form-control form-control-sm shadow-sm rounded-lg">
                                    <option value="" [selected]="true">select</option>
                                    <option *ngFor="let course of cources" [value]='course.COURSE_ID'>{{course.COURSE_NAME}}
                                    </option>
        
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label for="sel1">Grade Schedule :</label> 
                                <select [(ngModel)]='schedulId ' id="sel1" (change)="schedulChange()" class="form-control form-control-sm shadow-sm rounded-lg">
                                    <option value="" [selected]="true">select</option>
                                    <option *ngFor="let schedul of scheduls" [value]='schedul.COURSESHD_ID'>
                                        {{schedul.COURSESHD_NAME}}</option>
    
                                </select>




                             
                            </div>
                        </div>
                     
                
                    </div>

        </div>

        <div *ngIf="table.length">
            <table id="patreggraph" datatable class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                style="width:100%">
                <thead class="top bg-info"  style="background-color: #0d9bc4; color:#ffffff;">
                    <tr>
                        <th>
                            Grade Schedule </th>
                        <!-- <th> Chapter Name  </th> -->
                        <th>Assignment Name</th>

                        <th>Assignment End Date</th>
                        <th> Download Assignment</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of table">
                        <td>{{item.ASSIGNMENT_COURSE_SCHEDULE}}</td>
                        <!-- <td>{{item.CHAPTER_NAME}}</td> -->
                        <td>{{item.ASSIGNMENT_NAME}}</td>
                        <td>{{item.ASSIGNMENT_END_DATE}}</td>
                        <td><a class="pointer" (click)="download(item.ASSIGNMENT_UPLOAD)"   >Download</a>
                        </td>
                        <td>{{item.AS_UPLOAD}}</td>
                        <!-- <td style="display: flex;"> <input type="image"
                            src="./../../../assets/images/sprites/edit.svg" class="edit_icon"
                            data-toggle="modal" data-target="#myModal" (click)="edit(item)">
                       
                    </td> -->
                    <td><img *ngIf="item.AS_UPLOAD=='Pending'" data-toggle="modal" data-target="#myModal" src="./../../../assets/images/sprites/edit.svg" class="edit_icon" (click)="edit(item)" >
                    </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
      
        </div>
        </div>
        </div>

            <!-- Modal -->
    <div class="modal fade" id="myModal" role="dialog"  data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                   <input type="file"  (change)="change($event)" accept=".doc,.docx,.pdf">
                   <br>
                   <span>
                    Please upload pdf and doc file formats only.          
                    </span>
                 
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="save()" class="btn btn-default" >Save</button>
                    <button type="button"  class="btn btn-default" data-dismiss="modal" id="md_close">Close</button>
                </div>
            </div>

        </div>
    </div>
