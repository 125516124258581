import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-courcesessions',
  templateUrl: './courcesessions.component.html',
  styleUrls: ['./courcesessions.component.css']
})
export class CourcesessionsComponent extends BaseComponent implements OnInit {
  courses: [] = [];
  courseId: string = ''
  scheduleId: any = '';
  schedules: [] = []
  table: any = [];
  isActive: boolean = false;
  params: any = {};
  chapters: Array<any> = [];
  trainers: Array<any> = [];
  planData:any={};
  eventMyForm:FormGroup;
  startTime: string = null;
  Date:any;
  eventData: any;
  constructor( CommonService: CommonService,  toastr: ToastrService, private active: ActivatedRoute,private fb:FormBuilder) {
    super(CommonService,toastr);
    this.getCourses();
    active.queryParams.subscribe(
      (res) => {
        if (Object.keys(res).length) {
          this.courseId = res.cId;
          this.scheduleId = res.csId;
          this.params = res;
          this.courseChange();
          this.loadSessions();
          this.isActive = true;
          this.loadTrainersAndChapters();
        }
      })
  }

  ngOnInit(): void {
    this.createForm();
    this.createEventForm();
    this.dtOptions={
      order:[]
    }
  }
  createForm(){
    this.myForm=this.fb.group(
      {
        courseSh:[''],
        CSS_TITLE:['',Validators.required],
        CSS_DESCRIPTION:['',Validators.required],
        CSS_ACTIVITIES:[''],
        CSS_REFERENCE:['']
      }
    )
  }
  createEventForm(){
    this.eventMyForm=this.formBuilder.group({
      COURSE_ID: ['', Validators.required],
      COURSESCHEDULE_ID: ['', Validators.required],
      APPOINTMENT_NAME: ['', Validators.required],
      APPOINTMENT_DATE: ['', Validators.required],
      APPOINTMENT_STARTTIME: ['', Validators.required],
      APPOINTMENT_ENDTIME: [{ value: '', }, [Validators.required]],
      APPOINTMENT_DESCRIPTION: ['', Validators.required]
    })
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.deactivateSpinner();
      this.courses = res
    }, e => { this.deactivateSpinner(); })
  }

  courseChange() {
    this.activeSpinner();
    let data = {
      "CourseId": this.courseId
    }
    this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
      this.deactivateSpinner();
      this.schedules = res;
    }, e => { this.deactivateSpinner(); })
  }
  schedulChange() {
    this.activeSpinner();
    this.CommonService.getCourseScheduleSession(this.scheduleId).subscribe((res: any) => {
      this.deactivateSpinner();
      this.table = [];
      setTimeout(() => {
        this.table = res;
        this.table.map((item) => {
          item.CS_SCHEDULE_DELAY = item.CS_SCHEDULE_DELAY || 2;
        })
      }, 10)

    }, err => {
      this.deactivateSpinner();
    })
  }
  submit() {
    let array: Array<any> = [];
    this.table.map((item: any) => {
      let data = {
        CsId: item.CS_ID,
        SessionUpdate: item.CS_SCHEDULE_DELAY,
        ChapterId: item.CS_CHAPTER_ID,
        OffLineOnline: item.CS_ONLINE_OFFLINE,
        TrainerId: item.CS_TRAINER_ID,
        TenantCode: localStorage.getItem('TenantCode')
      }
      array.push(data);
    })
    this.CommonService.setCourseScheduleSessions({ data: array }).subscribe((res: any) => {
      this.toastr.success("Successfully Updated");
      setTimeout(() => { location.reload() }, 200)
      window.history.back()
    }, (e: any) => {

    })
  }

  loadSessions() {
    let payLoad = {
      COURSESHD_ID: this.scheduleId,
      STARTDATE: this.params.sDate,
      ENDDATE: this.params.eDate,
      USERID: localStorage.getItem('UserId'),
      TENANT_CODE: localStorage.getItem('TenantCode'),
    }
    this.activeSpinner();
    this.CommonService.postCall('LoadCourseScheduleSession', payLoad).subscribe(
      (res) => {
        this.table = res;
        this.deactivateSpinner();
      },
      err => {
        console.log(err);
        this.deactivateSpinner();
      }
    )

  }

  loadTrainersAndChapters() {
    let cPayload = {
      CHAPTER_CS_ID: this.scheduleId
    };
    let tPayload = {
      COURSE_TRAINER_COURSESHD_ID: this.scheduleId
    }
    let chapters = this.CommonService.postCall('LoadChaptersByCourseSchedule', cPayload);
    let trainers = this.CommonService.postCall('LoadAssignTrainers', tPayload);
    forkJoin([chapters, trainers]).subscribe(res => {
      this.chapters = res[0];
      this.trainers = res[1];
    }, err => {
      console.log(err)
    })
  }
  viewPlan(data){
     this.planData=data;
  }
  close(){
    this.planData={};
    this.myForm.reset();
  }
  onSubmit(form:FormGroup){
    let payLoad=form.getRawValue();
    payLoad['CSS_COURSE_SCHEDULE_ID']=this.planData['CS_COURSESHD_ID']||this.scheduleId;
    payLoad['CSS_CS_SESSION_ID']=this.planData['CS_ID'];
    payLoad['CSS_TENANTCODE']=parseInt(this.TenantCode);
    payLoad['CSS_CREATED_BY']=this.userId;
    payLoad['CSS_STATUS']=true;
    this.activeSpinner();
    this.CommonService.postCall('SaveLessionPlan',payLoad).subscribe(
      (res:any)=>{
        this.deactivateSpinner();
        this.toastr.success('Information Saved Successfully');
        document.getElementById('md_close').click();
      },err=>{
        this.deactivateSpinner();
      }
    )

  }
  riseEvent(data){
    this.eventData=data;
  }
  eventclose(){
this.eventMyForm.reset();
  }
  timeChange(endTime) {
    let controls = this.eventMyForm.controls;
    let stime: any = controls['APPOINTMENT_STARTTIME'].value;
    let econtrol = controls['APPOINTMENT_ENDTIME'];

    if (!stime) {
      this.toastr.warning('Please selece start time')
      econtrol.setValue(null)
      return
    }
    var start = moment.utc(stime, "HH:mm");
    var end = moment.utc(endTime, "HH:mm");

    var d = moment.duration(end.diff(start));
    if (d['_milliseconds'] > 0) {

    } else {
      this.toastr.warning("End Time should be more than start time")
      econtrol.setValue(null)
    }
 
  }
  eventOnSubmit(form: FormGroup) {
    let payload = form.value;
    payload.TENANT_CODE = this.TenantCode;
    this.activeSpinner();
    if (this.isEdit) {
      payload.LASTMDFBY = this.userId;
      payload.APPOINTMENT_ID = this.editData.APPOINTMENT_ID
      this.CommonService.postCall('UpdateEvent', payload).subscribe((res) => {
        this.deactivateSpinner();
        this.toastr.success(" Event Updated Successfully");
        document.getElementById('md_close1').click()
        // this.loadGrid();
      }, err => { this.deactivateSpinner(); this.toastr.error(err.error ? err.error : "Event not updated ") })
    } else {
      payload.CREATEDBY = this.userId;
      // ContentType=100
      this.CommonService.postCall('CreateEvent', payload).subscribe((res) => {
        this.deactivateSpinner();
        this.toastr.success("Event Created Successfully");
        document.getElementById('md_close1').click()
        // this.loadGrid()
      }, err => { this.deactivateSpinner(); this.toastr.error(err.error ? err.error : "Event not created ") })
    }


  }
}
