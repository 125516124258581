<div>
    <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="add()" data-toggle="modal"
            data-target="#myModal_project">Add</button>

    </div>
    <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
        <thead>
            <tr>
                <th>
                    Client Name</th>
                <th>Project Title</th>
                <th> Project Location</th>
                <th>Role</th>
                <th> Duration From</th>
                <th> Duration To</th>
                <th>Operation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let item of table;let i=index'>
                <td>{{item.ClientName}}</td>
                <td>{{item.ProjectTitle}}</td>
                <td>{{item.ProjectLocation}}</td>
                <td>{{item.Role}}</td>
                <td>{{item.DurationFrom|date:'dd-MM-yyyy'}}</td>
                <td>{{item.DurationTo|date:'dd-MM-yyyy'}}</td>
                <td class=" text-center"><input type="image" src="./../../../assets/images/sprites/edit.svg"
                        class="edit_icon text-center" data-toggle="modal" data-target="#myModal_project"
                        (click)="edit(item,i)"> </td>
            </tr>
        </tbody>
    </table>
    <p class="text-center" *ngIf='!table.length'>No Records</p>
</div>

<div class="modal" id="myModal_project" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 *ngIf="!isEdit" class="modal-title"> Add Project</h4>
                <h4 *ngIf="isEdit" class="modal-title"> Edit Project</h4>
                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Client <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input formControlName='ClientName' type="text"
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                </div>
                                <div *ngIf="myForm.get('ClientName').touched">
                                    <span class="text-danger" *ngIf="myForm.get('ClientName').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Project Title <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="text" formControlName='ProjectTitle'
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                </div>
                                <div *ngIf="myForm.get('ProjectTitle').touched">
                                    <span class="text-danger" *ngIf="myForm.get('ProjectTitle').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Duration <span class="text-danger">*</span></label>
                            <div class="col-md-3 inputGroupContainer">
                                <div class="input-group">
                                    <input type="date" formControlName='DurationFrom'
                                        class="form-control form-control-sm shadow-sm rounded-lg">


                                </div>
                                <div *ngIf="myForm.get('DurationFrom').touched">
                                    <span class="text-danger" *ngIf="myForm.get('DurationFrom').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                            <label class="col-md-0 control-label">To</label>
                            <div class="col-md-3 inputGroupContainer">
                                <div class="input-group">
                                    <input type="date" formControlName='DurationTo'
                                        class="form-control form-control-sm shadow-sm rounded-lg">


                                </div>
                                <div *ngIf="myForm.get('DurationTo').touched">
                                    <span class="text-danger" *ngIf="myForm.get('DurationTo').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Project Location Type <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select type="number" formControlName='ProjectLocationType'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select Project Location Type </option>
                                        <option *ngFor='let item of locationTypes' [value]='item.DICTIONARYNAME'>
                                            {{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('ProjectLocationType').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('ProjectLocationType').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Project Location <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select placeholder="" class="form-control" formControlName="ProjectLocation">
                                        <option value="" selected>Select Project Location</option>
                                        <option *ngFor='let item of locations' [value]='item.DICTIONARYNAME'>
                                            {{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('ProjectLocation').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('ProjectLocation').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Employment Type <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select placeholder="" class="form-control" formControlName="EmploymentType">
                                        <option value="" selected>Select a Employment Type</option>
                                        <option *ngFor='let item of empTypes' [value]='item.DICTIONARYNAME'>
                                            {{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>
                                <div *ngIf="myForm.get('EmploymentType').touched">
                                    <span class="text-danger" *ngIf="myForm.get('EmploymentType').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Project Details
                            </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea formControlName='ProjectDetails'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                    </textarea>
                                </div>

                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Role
                            </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="text" formControlName='Role'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                </div>

                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Role Description
                            </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea formControlName='RoleDescription'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                    </textarea>
                                </div>

                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Team Size </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select placeholder="" class="form-control" formControlName="TeamSize">
                                        <option value="" selected>Select Team Size</option>
                                        <option *ngFor='let item of teamSize'>{{item}}</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Skills Used
                            </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea formControlName='SkillsUsed'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                    </textarea>
                                </div>

                            </div>
                        </div>
                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id="md_close_project" (click)="close()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>