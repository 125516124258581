import { Component, OnInit } from '@angular/core';

import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.css']
})
export class FolderComponent implements OnInit {
  treeArray:Array<{name:string,id:string,childs:Array<any>,parent:string}>=[
    {
      name:'satya',
      id:'abc',
      childs:[
       {
         name:'test1',
         id:'abc1',
         childs:[]
        },
        {
         name:'Test2',
         id:'abc1',
         childs:[]
       }
      ],
      parent:''
    },
    {
     name:'Sheshu',
     id:'abc1',
     childs:[],
     parent:''
    },
    {
     name:'Srikanth',
     id:'abc1',
     childs:[],
     parent:''
   }
  ]
  personalTree:Array<any>=[];
  sharedTree:Array<any>=[];
  files:Array<any>=[];
  public fileCall:boolean=false;
  count :number=0;
  constructor(private commonservice:CommonService) { 
    this.GetData();
  }

  ngOnInit(): void {
    
    this.commonservice.fileObs.subscribe((res:any)=>{
      this.count++
      if(this.count>1) this.fileCall=true;
      
      this.files=res;
    })
  }
  activeSpinner(){
    this.commonservice.activateSpinner();
  }

  deactivateSpinner(){
    this.commonservice.deactivateSpinner()
  }
   GetData(){
     this.activeSpinner();
     this.commonservice.postCall('FolderManagement/GetList',{"CREATEDBY":localStorage.UserId}).subscribe((res:any)=>{
       this.deactivateSpinner();
        let data={
          Text:'Personal Folders',
          Value:"-1",
          Childnode:res.personal
        }
        this.personalTree.push(data);
     },err=>{
       this.deactivateSpinner();
       console.log(err)
     })
   }
   ViewFile(item: any) {

    window.open(`https://lmsqa.dhanushinfotech.com/api/FolderManagement/Getfilename/${item.FILEID}`, 'name','height=250,width=550,toolbar=0,menubar=0,location=0');
   
  }

}