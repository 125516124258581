<div>

  <div style="margin-top:70px;">
    <div class="content-wrapper p-0">

      <!-- banner -->
      <div class="container-fluid ">


        <div class="row">
          <div class="col-xl-12">
            <img src="./assets/img/strip.png"  class="img-fluid w-100" />
          </div>
        </div>
        <!-- content -->


        <div class="row">
          <div class="col-xl-8 pt-4">
            
            <div class="container-fluid" *ngIf="roleId==3">
              <div id="accordion" class="accordion">
                  <div class="card mb-0 active">
                      <div class="card-header collapsed accordion1" data-toggle="collapse" href="#collapseOne">
                          <a class="card-title">
                            My Enrolled Grade 
                          </a>
                      </div>
                      <div id="collapseOne" class="card-body collapse show" data-parent="#accordion" >
                        <div *ngIf="course.classes&&course.classes.length">
                          <h5>Grade  that you have enrolled for</h5>
                          <table class="table table-striped table-responsive-md btn-table table-bordered text-dark">
                            <thead>
                              <tr>
                                <th> Name</th>
                                <!-- <th>Category</th> -->
                                <th>Grade 
                                  Content</th>
                                <th>Exam</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of course.classes">
                                <td>{{item.Name}}</td>
                                <!-- <td>Medical</td> -->
                                <td><a class="a_click" (click)="navigate(item,'home/learningmaterial')">view</a></td>
                                <td><a class="a_click" (click)="navigate(item,'home/postassessment')">Take Exam</a></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-dark" *ngIf="!course.classes|| !course.classes.length">
                          No records to Display
                        </div>
                      </div>
                  
                      <div class="card-header collapsed accordion1 mt-1" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                          <a class="card-title">
                            Online Sessions
                          </a>
                      </div>
                      <div id="collapseTwo" class="card-body collapse" data-parent="#accordion" >
                        <div *ngIf="course.sessions&&course.sessions.length">
                          <h5>Online Sessions for today</h5>
                          <table class="table table-striped table-responsive-md btn-table text-dark">
                            <thead>
                              <tr>
                                <th> Session Name </th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Join</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of course.sessions">
                                <td>{{item.SessionName}}</td>
                                <td>{{item.StartTime}}</td>
                                <td>{{item.EndTime}}</td>
                                <td><a *ngIf='item.URL' href="{{item.URL}}" target="blank">Join Session</a>
                                  <span *ngIf='!item.URL' >Host Not Joined</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div  class="text-dark" *ngIf="!course.sessions || !course.sessions.length">
                          No records Display
                        </div>
                      </div>
                  
                   
                  </div>
              </div>
          </div>

            <!-- <div class="container-fluid" *ngIf="roleId!=2">

              <div class="accordion" id="accordionExample" >
                <div class="card active mb-1">
                  <div class="card-header p-1 cardheader-bg collapsed" data-toggle="collapse" id="headingOne">
                    <h2 class="mb-0">
                      <button class="btn btn-link p-1 btn-block text-white text-left" type="button"
                        data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        My Enrolled Courses
                      </button>
                    </h2>
                  </div>

                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div class="card-body p-3">

                      <div *ngIf="course.classes&&course.classes.length">
                        <h5>Courses that you have enrolled for</h5>
                        <table class="table table-striped table-responsive-md btn-table table-bordered">
                          <thead>
                            <tr>
                              <th> Name</th>
                            
                              <th>Course
                                Content</th>
                              <th>Exam</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of course.classes">
                              <td>{{item.Name}}</td>
                             <td>Medical</td>
                              <td><a class="a_click" (click)="navigate(item,'home/learningmaterial')">view</a></td>
                              <td><a class="a_click" (click)="navigate(item,'home/postassessment')">Take Exam</a></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div *ngIf="!course.classes|| !course.classes.length">
                        No records to Display
                      </div>
                    
                    </div>
                  </div>
                </div>
                <div class="card active  mb-1">
                  <div class="card-header p-1 cardheader-bg collapsed" data-toggle="collapse" id="headingTwo">
                    <h2 class="mb-0">
                      <button class="btn btn-link p-1 btn-block text-white text-left" type="button"
                        data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                        aria-controls="collapseTwo">
                        Online Sessions
                      </button>
                    </h2>
                  </div>
                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div class="card-body">
                  
                    </div>
                  </div>
                </div>
                
              </div>
            </div> -->


          </div>
          <div class="col-xl-4  pt-4 pr-3">


            <div class="container ">
              <ul class="nav nav-tabs tabactive ml-3" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active tabactive" data-toggle="tab" href="#tabs-1" role="tab">Discussion</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">News</a>
                </li>

              </ul><!-- Tab panes -->
              <div class="tab-content bg-white shadow" style="border-radius:15px;">
                <div class="tab-pane    active p-2" id="tabs-1" role="tabpanel">
                  <div class="list list-hover" >
                    <!-- <ul [innerHTML]='data.ulthreads'></ul> -->
                    <ul [innerHTML]='data.ulDiscussions'></ul>
                  </div>
                  <!-- <div class="list list-hover">
          
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
   
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
           
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">Bravio Application</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By James</a>
                </div>
               
              </div>
            </div>
            
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
                
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
              
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">Quick Reports</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Ana</a>
                </div>
             
              </div>
            </div>
          
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
               
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
                
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                </div>
        
        
             
        
                <div> 
        
                
                </div>
           
              </div>
            </div>
       
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
           
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
                
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                </div>
              
              </div>
            </div>
            
                      <div class="list-item hoverable  p-lg-2 mb-0">
                        <div class="d-flex align-items-center">
                         
                          <div class="symbol symbol-40 symbol-light mr-4">
                            <span class="symbol-label bg-hover-white">
                              <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                            </span>
                          </div>
                   
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                            <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                          </div>
                         
                        </div>
                      </div>
                         
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
          
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
               
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                </div>
        
              </div>
              
            
            </div>
        
        
            <div class="list-item hoverable pb-5 mb-0">
              <div class="float-right">
         <button class="btn btn-sm btn-primary">View More...</button>
              
              </div>
              
            
            </div>
           
          </div> -->

                </div>
                <div class="tab-pane fade in p-2" id="tabs-2" role="tabpanel">
                  <!-- <p>News</p> -->
                  <ul [innerHTML]='data.ulNews'></ul>
                </div>

              </div>


            </div>

          </div>


        </div>

      </div>



    </div>
    <!-- content-wrapper ends -->
    <!-- partial:partials/_footer.html -->

    <!-- partial -->
  </div>
</div>