import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CommonService } from '../services/common.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-maincontent',
  templateUrl: './maincontent.component.html',
  styleUrls: ['./maincontent.component.css']
})
export class MaincontentComponent implements OnInit {
  roleId:number|string=localStorage.getItem('RoleId');
  constructor(private DashboardService:DashboardService,private route:Router,private commonService:CommonService) {
    if(this.roleId!=2){
    this.getCource();
    this.getDashboard()
    }
   }
  
  course:any={
    isSessions:false,
    isClasses:true
  }
   data:any={}
  ngOnInit(): void {
 
  }

  getCource(){
      this.commonService.activateSpinner()
    this.DashboardService.getCource().subscribe((data:any)=>{
      this.course={...this.course,...data}
      this.stopSpinner()
    },(e)=>{
     
      this.stopSpinner()
    })
  }
  toggleCource(key,collapse){
    this.course.isSessions=false;
    this.course.isClasses=false;
    this.course[key]=!collapse;
  }
  navigate(data,url){
    delete data.Name;
    this.route.navigate([url],{queryParams:data})
  }
  getDashboard(){
    this.commonService.activateSpinner()
    this.DashboardService.loadDashboard().subscribe((res:any)=>{
      this.data=res;
      this.stopSpinner()
    },(e)=>{
     
      this.stopSpinner()})
  }

  stopSpinner(){
    this.commonService.deactivateSpinner()
  }
}
