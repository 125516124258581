import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AboutMeComponent } from 'src/app/components/userregistration/about-me/about-me.component';
import { ContactDetailsComponent } from 'src/app/components/userregistration/contact-details/contact-details.component';
import { EducationComponent } from 'src/app/components/userregistration/education/education.component';
import { LanguagesComponent } from 'src/app/components/userregistration/languages/languages.component';
import { PersonalDataComponent } from 'src/app/components/userregistration/personal-data/personal-data.component';
import { PreviousSchoolReferenceComponent } from 'src/app/components/userregistration/previous-school-reference/previous-school-reference.component';
import { ProjectsComponent } from 'src/app/components/userregistration/projects/projects.component';
import { SkillsComponent } from 'src/app/components/userregistration/skills/skills.component';
import { AddressComponent } from 'src/app/components/userregistration/address/address.component';
import { WorkExperienceComponent } from 'src/app/components/userregistration/work-experience/work-experience.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-edit-user-registration',
  templateUrl: './edit-user-registration.component.html',
  styleUrls: ['./edit-user-registration.component.css']
})
export class EditUserRegistrationComponent implements OnInit {
  case: number = 1;
  token: string;
  role:string;
  editData: any = {};
  childs: any = {

  }
  constructor(public CommonService: CommonService, private fb: FormBuilder, public toastr: ToastrService, private active: ActivatedRoute) {
    active.queryParams.subscribe(
      (res) => {
        if (res.token) {
          this.token = res.token;
          this.role = res.role;
          this.edit()
        } else {
          window.history.back()
        }
      })
  }

  ngOnInit(): void {
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  selectTab(tab) {
    this.case = tab;
  }

  edit() {
    this.activeSpinner();
    this.CommonService.postCall('EditRegistration', { VerificationToken: this.token }).subscribe(
      (res: any) => {
        this.editData = res;
        this.deactivateSpinner()
      }, err => {
        this.deactivateSpinner()
      }
    )
  }
  save() {
    //  console.log((this.childs['LanguagesList'] as LanguagesComponent).lanuageData);
    //  return;
    let prControl: FormGroup = this.childs['UserProfileData'].myform;
    let prValue=prControl.value;
    if (!prControl.valid) {
      let controls = prControl.controls
      Object.keys(controls).map(key => {
        controls[key].markAsTouched();
      });
      this.toastr.warning('Please Enter Mandatory Fields')
      return
    }
    let payLoad:any={
      UserProfileData:{},
      ContactDetailsList:[],
      EducationTypeList:(this.childs['EducationTypeList'] as EducationComponent).educationData,
      SkillsList:(this.childs['SkillsList'] as SkillsComponent).skillData,
      AddressList:(this.childs['AddressList'] as AddressComponent).Address,
      LanguagesList:(this.childs['LanguagesList'] as LanguagesComponent).lanuageData,
      ProjectsList:(this.childs['ProjectsList'] as ProjectsComponent).projectData,
      WorkExperienceList:(this.childs['WorkExperienceList'] as WorkExperienceComponent).WorkExperienceData,
      PreviousSchoolRefList:(this.childs['PreviousSchoolRefList'] as PreviousSchoolReferenceComponent).PreviousSchoolRefData,
      userfrimages:(this.childs['aboutMe'] as AboutMeComponent).fileName
    }
    let personalData=(this.childs['UserProfileData'] as PersonalDataComponent).personalData
    let contactDetails=(this.childs['ContactDetailsList'] as ContactDetailsComponent).personalData;
    let array=[];
    contactDetails.map(item=>{
      let obj={
        type:item['TYPE'],
        CommunicationId:item['COMMUNICATIONID'],
        EmailId:item['EMAILID'],
        MobileNo:item['MOBILENO'],
        IsPrimary:item['ISPRIMARY'],
        IsConfirmed:item['IsConfirmed']
      }
      array.push(obj)
    })
    payLoad.UserProfileData={
      UserImage:personalData['USERIMAGE'],
      TenantCode:personalData['TenantCode'],
      CreatedBy:personalData['UserId'],
      Status:prValue['status'],
      Title:prValue['Title'],
      FirstName:prValue['FirstName'],
      LastName:prValue['LastName'],
      UserId:personalData['UserId'],
      objUserpersonal:{
        DOB:prValue['dob'],
        Gender:prValue['Gender'],
        MartialStatus:prValue['MartialStatus'],
        PROFILESUMMARY:personalData['PROFILESUMMARY']||''
      },
      YearOfRegistration:prValue['YearOfRegistration']|| personalData['YearOfRegistration'],
      CourseId:prValue['Branch'],
      RollNumber:prValue['idNumber'],
      Aadhar_Number: prValue['Aadhar_Number'], 
      Caste: prValue['Caste'], 
      SubCaste: prValue['SubCaste'],
      Medium: prValue['Medium'],  
      "ParentName":prValue['ParentName']||personalData['ParentName']||"",
      "ParentMobileNumber":prValue['ParentMobileNumber']|| personalData['Parent_Mobile_Number']||"",
      "ParentRelationShip":prValue['ParentRelationShip']|| personalData['ParentRelationShip']||null
    }
    payLoad.ContactDetailsList=array;
     console.log(payLoad)
    // return
    this.activeSpinner();
    this.CommonService.postCall('UpdateRegistration',payLoad).subscribe((res)=>{
      this.deactivateSpinner();
      this.toastr.success('User Updated Successfully');
      this.close();
    },err=>{
      this.deactivateSpinner();
      this.toastr.warning(err.error?err.error:'user not updated')
    })
    
  }
  close() {
    window.history.back();
  }
}
