<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Schedule Assessment</h5>
                        </div>
                        <div class="card-body">
                            <div class="text-right">
                                <button type="button" class="btn btn-primary mb-1"  data-toggle="modal"
                                    data-target="#myModal" (click)="add()" >Add</button>      
                            </div>
                            <div *ngIf="table.length">
                                <table id="patreggraph" datatable
                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                    style="width:100%">
                                    <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                        <tr>
                                            <th>
                                                Assessment Name</th>
                                            <th> Grade Name </th>
                                            <th>
                                                Grade Schedule Name
                                            </th>
                                            <th>	Start Date</th>
                                       
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table">
                                               <td>{{item.ASSESSMENT_ASSESSMENT_NAME}}</td>
                                               <td>{{item.COURSE_NAME}}</td>
                                               <td>{{item.COURSESHD_NAME}}</td>
                                               <td>{{item.SA_DATE}}</td>
                                              <td class=" text-center"><input type="image" data-toggle="modal"  data-target="#myModal"    src="./../../../assets/images/sprites/edit.svg" class="edit_icon text-center" (click)="edit(item)"> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-center" *ngIf="!table.length">
                                No Records to display
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 *ngIf="!isEdit" class="modal-title"> Add Schedule Assessment</h4>
                <h4 *ngIf="isEdit" class="modal-title"> Edit Schedule Assessment</h4>
                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>   
                         
                        <div class="form-group row">
                            <label class="col-md-4 control-label">	Grade Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select  [(ngModel)]='courceId' (change)="courseChange()" class="form-control form-control-sm shadow-sm rounded-lg" formControlName="SA_COURSE_ID"  >
                                        <option  value="" [selected]="true">select</option>
                                        <option   *ngFor="let course of courses" [value]='course.COURSE_ID'>{{course.COURSE_NAME}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>
                     
                        <div class="form-group row">
                            <label class="col-md-4 control-label">			Grade Schedule </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select [(ngModel)]='scheduleId ' (change)="sheduleChange()"   class="form-control form-control-sm shadow-sm rounded-lg" formControlName="SA_COURSE_SCHEDULE_ID">
                                        <option  value="" [selected]="true">select</option>
                                        <option   *ngFor="let schedule of scheduls" [value]='schedule.COURSESHD_ID'>{{schedule.COURSESHD_NAME}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Chapters <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select *ngIf="false"  class="form-control form-control-sm shadow-sm rounded-lg"   >
                                        <option  value="" [selected]="true">select</option>
                                        <option   *ngFor="let course of courses" [value]='course.COURSE_ID'>{{course.COURSE_NAME}}
                                        </option>
                                    </select>
                                    <ng-multiselect-dropdown formControlName='SA_CHAPTERS' style="width:96%" *ngIf="isData" [placeholder]="'select cource'"
                                    [settings]="dropdownSettings" [data]="dropdownList"
                                    (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" [(ngModel)]="selectedItems">
                                </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label">	Assessment Name </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select   class="form-control form-control-sm shadow-sm rounded-lg" formControlName="SA_ASSESSMENT_ID"  >
                                        <option  value="" [selected]="true">select</option>
                                        <option   *ngFor="let ass of assessments" [value]='ass.ASSESSMENT_ID'>{{ass.ASSESSMENT_ASSESSMENT_NAME}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>
                      
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                               Date <span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group" > 
                                    <input type="date" formControlName='SA_DATE' placeholder="" class="form-control"></div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Start Time <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> 
                                    <input type="time" [(ngModel)]="startTime" formControlName='SA_START_TIME' placeholder="" class="form-control"></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                End Time  <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> 
                                    <input type="time" (change)="timeChange($event.target.value)" formControlName='SA_END_TIME' placeholder="" class="form-control"></div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label">Training Mode  <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='SA_TRAINING_MODE' class="form-control form-control-sm shadow-sm rounded-lg"  >
                                        <option  value="" [selected]="true">select</option>
                                        <option  value=1 >Offline</option>
                                        <option value=2>Online </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" id="isChecked" *ngIf="!isProctoring">
                            <label class="col-md-4 control-label line_2 rem_1">
                               Is Proctoring  </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> 
                                    <input type="checkbox"  class="checkbox" formControlName="ALLOW_PROCTORING" (click)="proctoring($event)" ></div>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="isChecked">
                            <label class="col-md-4 control-label">Proctoring Types <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                  
                                    <ng-multiselect-dropdown formControlName='PROCTORING_TYPE' style="width:96%" *ngIf="isDataProctoring" [placeholder]="'select Proctoring'"
                                    [settings]="dropdownSettings" [data]="dropdownListProctoring"
                                    (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" [(ngModel)]="selectedProctoring">
                                </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="isChecked ">
                            <label class="col-md-4 control-label line_2 rem_1">
                               Remainder <span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group" > 
                                    <input type="number" formControlName='REMINDER_COUNT'  class="form-control" ></div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)" [disabled]="myForm.invalid">Save</button> 
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)" [disabled]="myForm.invalid">Update</button> 
                <button
                    type="button" class="btn btn-danger" id="md_close"  (click)="close()" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
