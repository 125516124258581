import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.css']
})
export class AssignmentsComponent implements OnInit {
  assignments:Array<any>=[];

  constructor(private route:Router,private CommonService: CommonService) { 
    this.getLoadAssignments()
  }

  ngOnInit(): void {
  }
  activeSpinner(){
    this.CommonService.activateSpinner();
  }

  deactivateSpinner(){
    this.CommonService.deactivateSpinner()
  }


  add(){
    this.route.navigate(['home/addAssignments'])
  }
  // getLoadAssignments(){
  //   this.activeSpinner();
  //   this.CommonService.loadAssignments().subscribe((res:any)=>{
  //      this.deactivateSpinner()
  //      ;
  //     this.assignments=res;
  //   },(err)=>{ this.deactivateSpinner();})
  // }

  getLoadAssignments(){
    this.activeSpinner();
    let payLoad: any = {
      "TENANTCODE":localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('Assignments/GetList',payLoad).subscribe((res: any) => {
      this.assignments=[];
      setTimeout(() => {
        this.assignments = res;
      }, 10)
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner()
    })
  }
 
  edit(data){
    let params={
      id:data.ASSIGNMENT_ID
    }
    this.route.navigate(['home/addAssignments'],{queryParams:params})
  }
}
