<div>
    <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="add()" data-toggle="modal"   data-target="#myModal_lan">Add</button>

    </div>
    <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
        <thead>
            <tr>
                <th>Language</th>
                <th>Proficiency Level</th>
                <th>Read</th>
                <th>Write</th>
                <th>Speak</th>          
                <th>Operation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let item of table;let i=index'>
                <td>{{item.Language }}</td>
                <td>{{item.ProficiencyLevel }}</td>
                <td>{{item.ReadL }}</td>
                <td>{{item.WriteL }}</td>
                <td>{{item.SpeakL }}</td>
                <td class=" text-center"><input type="image" src="./../../../assets/images/sprites/edit.svg"
                        class="edit_icon text-center" data-toggle="modal"   data-target="#myModal_lan" (click)="edit(item,i)"> </td>
            </tr>
        </tbody>
    </table>
    <p class="text-center" *ngIf='!table.length'>No Records</p>
</div>

<div class="modal" id="myModal_lan" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-md">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 *ngIf="!isEdit" class="modal-title"> Add Language </h4>
                <h4 *ngIf="isEdit" class="modal-title"> Edit  Language</h4>
                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Language  <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select  formControlName='Language'
                                    class="form-control form-control-sm shadow-sm rounded-lg">
                                    <option value="" selected>Select Language</option>
                                    <option *ngFor='let item of languages' [value]='item.DICTIONARYNAME'>{{item.DICTIONARYNAME}}</option>
                                </select>

                                </div>
                                <div *ngIf="myForm.get('Language').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Language').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Employement Status <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select  formControlName='ProficiencyLevel'
                                    class="form-control form-control-sm shadow-sm rounded-lg">
                                    <option value="" selected>Select Employement Status</option>
                                    <option *ngFor='let item of levels' [value]='item.DICTIONARYNAME'>{{item.DICTIONARYNAME}}</option>
                                </select>

                                </div>
                                <div *ngIf="myForm.get('ProficiencyLevel').touched">
                                    <span class="text-danger" *ngIf="myForm.get('ProficiencyLevel').hasError('required')">
                                        This field is required.
                                    </span>

                                </div>
                            </div>
                        </div>
                             
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Read Language </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="checkbox" formControlName="ReadL">
                                   
                                </div>
                                
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                               Write Language</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="checkbox" formControlName="WriteL">
                                    
                                </div>
                              
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Speak Language </label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <input type="checkbox" formControlName="SpeakL">
                                        
                                    </div>
                                  
                                </div>
                        </div>
                      
                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id="md_close_lan" (click)="close()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>