<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <h5 class="text-center"> Assignments</h5>
            <div class="custom_container">
                <form class="well form-horizontal"  [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">
                                Assignment Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input  [readonly]='isDisable' [(ngModel)]="data.ASSIGNMENT_NAME"  placeholder="Assignment Name" class="form-control" formControlName="ASSIGNMENT_NAME" type="text"></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Grade <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select  [(ngModel)]='courceId ' class="form-control form-control-sm shadow-sm rounded-lg" id="sel1" formControlName= "ASSIGNMENT_COURSE" (change)="courceChange()">
                                        <option [disabled]='isDisable' value="" [selected]="true">select</option>
                                        <option  [disabled]='isDisable' *ngFor="let course of cources" [value]='course.COURSE_ID'>{{course.COURSE_NAME}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Grade Schedule <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select  [disabled]='isDisable' [(ngModel)]='schedulId '  class="form-control form-control-sm shadow-sm rounded-lg" id="sel1" formControlName="ASSIGNMENT_COURSE_SCHEDULE" (change)="schedulChange()">
                                        <option [disabled]='isDisable' value="" [selected]="true">select</option>
                                        <option [disabled]='isDisable' *ngFor="let schedul of scheduls" [value]='schedul.COURSESHD_ID'>
                                            {{schedul.COURSESHD_NAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Assessment Name </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select [(ngModel)]="data.ASSIGNMENTS_SCHEDULE_ID" class="form-control form-control-sm shadow-sm rounded-lg" id="sel1" formControlName="ASSIGNMENTS_SCHEDULE_ID">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of assessments" [value]='item.ASSESSMENT_ID'>
                                            {{item.ASSESSMENT_ASSESSMENT_NAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">
                                Start Date</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input  min="{{minDate}}" (change)="myForm.get('ASSIGNMENT_END_DATE').setValue(null)" [readonly]='isDisable' class="form-control" type="date" [ngModel] ="data.ASSIGNMENT_START_DATE | date:'yyyy-MM-dd'" formControlName="ASSIGNMENT_START_DATE">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">
                                End Date</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input   min="{{minDate}}"  [ngModel] ="data.ASSIGNMENT_END_DATE | date:'yyyy-MM-dd'" (change)="endDateChange($event.target.value)" class="form-control" type="date" formControlName="ASSIGNMENT_END_DATE">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">
                                File upload <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input class="form-control" type="file" (change)="change($event)" accept=".doc,.docx,.pdf">   <span class="span" (click)="upload()">save</span>
                                   <br>  <div class="progress" *ngIf="progress" [ngStyle]='{"width":progress}'></div>
                                    <p>{{fileName}}</p>
                                
                                </div>
                                <p class="text-warning">Please upload pdf and doc file formats only.</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">
                                Maximum Marks <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <input  [ngModel] ="data.ASSIGNMENT_MAX_MARKS " formControlName='ASSIGNMENT_MAX_MARKS' class="form-control" type="number">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label">Status</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group"> <strong>:</strong> &nbsp;
                                    <select class="form-control form-control-sm shadow-sm rounded-lg" id="sel1" formControlName='AssignmentStatus'>
                                        <option [ngValue]='1' >Active</option>
                                        <option [ngValue]='0' >Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                       
                    </fieldset>
                </form>
                <div class="text-center">
                    <button type="button" class="btn btn-success" type="submit" (click)='onSubmit(myForm)' [disabled]="myForm.invalid">Submit</button> 
                    &nbsp;  <button class="btn btn-danger" (click)='back()' > Close</button>
                </div>
            </div>
        </div>
    </div>

</div>