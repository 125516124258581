import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import {dataDictionary}  from './../../dataDictionary'
@Component({
  selector: 'app-add-cource-schedule',
  templateUrl: './add-cource-schedule.component.html',
  styleUrls: ['./add-cource-schedule.component.css']
})
export class AddCourceScheduleComponent implements OnInit {
  isEdit: Boolean = false;
  id: string;
  minDate: any = moment().format('yyyy-MM-DD')
  cources: Array<any> = [];
  courceId: string = '';
  acadamicYears: Array<any> = [];
  courceYears: Array<any> = [];
  semesters: Array<any> = [];
  subjects: Array<any> = [];
  sections: Array<any> = [];
  regulations: Array<any> = [];
  countrys: Array<any> = [];
  locations: Array<any> = [];
  myForm: FormGroup
  rooms: Array<any> = [];
  seats: string;
  editData: any = {}
  courceTypes: Array<any> = [];
  COURSE_DURATION: string;
  constructor(private fb: FormBuilder, private CommonService: CommonService, active: ActivatedRoute, private toastr: ToastrService, private location: Location) {
    active.queryParams.subscribe((res) => {
      if (res.cId) {
        this.id = res.cId;
        // this.edit()
      }
    })
    // console.log(moment)
    this.getCourses();
    this.getAll()
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      COURSESHD_NAME: ['',],
      COURSESHD_COURSE_ID: ['', Validators.required],
      COURSESHD_COURSETYPE_ID: [0,],
      COURSESHD_ACADAMICYEAR: ['',Validators.required],
      COURSESHD_COURSEYEAR: ['',Validators.required],
      COURSESHD_SEMESTER: ['',Validators.required],
      COURSESHD_SUBJECT: ['',Validators.required],
      COURSESHD_SECTION: ['',],
      COURSESHD_REGULATION_ID: [0,],
      COURSESHD_COUNTRY: ['',],
      COURSESHD_LOCATION_ID: ['', Validators.required],
      COURSESHD_ROOM_ID: ['', Validators.required],
      COURSESHD_NOOFDAYS: ['', Validators.required],
      COURSESHD_NO_OF_STUDENTS: ['',],
      COURSESHD_STARTDATE: ['', ],
      COURSESHD_ENDDATE: ['', ],
      COURSESHD_STARTTIME: ['10:00:00', Validators.required],
      COURSESHD_ENDTIME: ['16:00:00', Validators.required],
      COURSESHD_STATUS: [1,],
    })


    if (this.id) {
      this.isEdit = true;
      this.edit()
    }
  }
  getCourses() {
    this.activeSpinner()
    this.CommonService.getAdminCourses().subscribe((res: any) => {
      this.cources = res;

      this.deactivateSpinner()
    }, e => {

      this.deactivateSpinner()
    })
  }
  getAll() {
    //// acadamic
    this.activeSpinner()
    let acadamic = this.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.AcademicYear});
    let year = this.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.CourseYear});
    let sem = this.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.Semester});
    // let sub = this.CommonService.postCall('GetSubjectByKey', {});
    let section = this.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.Sections});
    let reg = this.CommonService.getCall('GetRegulations');
    let country = this.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.Country});
    let loc = this.CommonService.postCall('GetLocations', { TENANT_CODE: localStorage.getItem('TenantCode') });
    let type = this.CommonService.postCall('GetCourseType', { TENANT_CODE: localStorage.getItem('TenantCode') })
    let dropdowns = ['acadamicYears','CourseYear', 'semesters', 'sections', 'regulations', 'countrys', 'locations', 'courceTypes']
    forkJoin([acadamic,year, sem, section, reg, country, loc, type]).subscribe((res) => {
      dropdowns.map((key, index) => {
        this[key] = res[index]
      })
      this.deactivateSpinner()

    }, err => { this.deactivateSpinner() })

  }

  getRoomsByLocation(value) {
    this.activeSpinner();
    let payLoad = {
      "ROOM_LOCATION_ID": value,
      TENANT_CODE: localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('GetRoomsByLocation', payLoad).subscribe((res: any) => {
      this.rooms = res;
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }

  getNumberofSeatsByRoom(value) {
    this.activeSpinner();
    let payLoad = {
      "ROOM_ID1": value,
      TENANT_CODE: localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('GetNumberofSeatsByRoom', payLoad).subscribe((res: any) => {
      if (res.length) {
        this.seats = res[0].ROOM_NO_OF_SEATS
      }
      this.deactivateSpinner();
    }, e => { this.deactivateSpinner() })
  }
  sTimeCahnge() {
    if (!this.courceId) { this.toastr.warning('Please select the cource'); this.myForm.controls['COURSESHD_STARTDATE'].setValue(null); return }
    this.getEndDateByCourseId();
  }
  activeSpinner() {
    this.CommonService.activateSpinner();
  }

  deactivateSpinner() {
    this.CommonService.deactivateSpinner()
  }

  onSubmit(form: FormGroup) {
    if (form.invalid) { this.toastr.warning('Please select mandatory fields'); return }
    let payLoad = this.myForm.getRawValue()
    payLoad["COURSESHD_AMOUNT"] = "";
    payLoad["COURSESHD_LOYALTYPOINTS"] = "";
    payLoad["COURSESHD_STUDENTS_REGISTERED"] = "";
    payLoad["COURSESHD_ALLOW_FEEDBACK"] = "false";
    payLoad['QUERY'] = '';
    payLoad['TENANT_CODE']= localStorage.getItem('TenantCode'),
    payLoad['LASTMDFBY']=localStorage.getItem('UserId')
    this.activeSpinner();
    if (this.isEdit) {
      payLoad['COURSESHD_ID'] = this.id;
      this.CommonService.postCall('UpdateCourseScheduleBatchPlan', payLoad).subscribe(() => {
        this.deactivateSpinner();
        this.toastr.success('Information updated successfully')
        window.history.back()
      }, err => {
        this.toastr.error(err.error?err.error:'schedule Not Updated');
        this.deactivateSpinner();
      })
    } else {
      payLoad['CREATEDBY'] = localStorage.getItem('UserId')
      this.CommonService.postCall('CreateCourseScheduleBatchPlan', payLoad).subscribe(() => {
        this.deactivateSpinner();
        this.toastr.success('Information saved successfully')
        window.history.back()
      }, err => {
        this.toastr.error(err.error?err.error:'schedule not created');
        this.deactivateSpinner()
      })
    }

  }
  edit() {
    this.isEdit = true;
    let payload = {
      "TENANT_CODE": localStorage.getItem('TenantCode'),
      "COURSESHD_ID": this.id
    }
    this.CommonService.postCall('EditCourseScheduleBatchPlan', payload).subscribe((res: any) => {
      if (res instanceof Array && res.length) {
        this.editData = res[0];
      } else {
        this.editData = res['dtCourseScehdule'];
      }
      this.deactivateSpinner();
      this.dataTransfer();
    }, err => { this.deactivateSpinner(); }
    )

  }

  dataTransfer() {
    let controls = this.myForm.controls;
    Object.keys(controls).map((key) => {
      let ctrl: AbstractControl = controls[key];
      ctrl.setValue(this.editData[key])

    });
    this.getRoomsByLocation(this.editData['COURSESHD_LOCATION_ID']);
    controls['COURSESHD_STARTDATE'].setValue(moment(this.editData['COURSESHD_STARTDATE']).format('yyyy-MM-DD'));
    controls['COURSESHD_ENDDATE'].setValue(moment(this.editData['COURSESHD_ENDDATE']).format('yyyy-MM-DD'))
    controls['COURSESHD_STARTTIME'].setValue(moment(this.editData['COURSESHD_STARTTIME']).format('HH:mm:ss'))
    controls['COURSESHD_ENDTIME'].setValue(moment(this.editData['COURSESHD_ENDTIME']).format('HH:mm:ss'))
    controls['COURSESHD_STATUS'].setValue(this.editData.COURSESHD_STATUS ? 1 : 0);
    let disabledItem: string[] = ['COURSESHD_COURSE_ID', 'COURSESHD_ACADAMICYEAR', 'COURSESHD_COURSEYEAR', 'COURSESHD_SEMESTER', 'COURSESHD_SUBJECT', 'COURSESHD_SECTION', 'COURSESHD_COUNTRY','COURSESHD_NAME', 'COURSESHD_LOCATION_ID','COURSESHD_NOOFDAYS','COURSESHD_NO_OF_STUDENTS','COURSESHD_ENDDATE']
    disabledItem.map((key) => {
      let ctrl: AbstractControl = controls[key];
      ctrl.disable({ onlySelf: true })
    })

    this.getSubjectsbyCourse();

  }

  timeChange(endTime) {
    let controls = this.myForm.controls;
    let stime: any = controls['COURSESHD_STARTTIME'].value;
    let econtrol = controls['COURSESHD_ENDTIME'];

    if (!stime) {
      this.toastr.warning('Please selece start time')
      econtrol.setValue(null)
      return
    }
    var start = moment.utc(stime, "HH:mm");
    var end = moment.utc(endTime, "HH:mm");

    var d = moment.duration(end.diff(start));
    if (d['_milliseconds'] > 0) {

    } else {
      this.toastr.warning("End Time should be more than start time")
      econtrol.setValue(null)
    }

  }

  setscheduleName(cource?) {
    if (cource) {
      this.activeSpinner();
      this.getSubjectsbyCourse();
      this.CommonService.postCall('GetNoOfSessionsByCourseId', { TENANT_CODE: localStorage.getItem('TenantCode'), COURSE_ID: this.courceId }).subscribe(
        (res: any) => {
          this.deactivateSpinner();
          if (res.length) {
            this.COURSE_DURATION = res[0].COURSE_DURATION;
          }
        }, e => { this.deactivateSpinner() })
    }
    if (!this.courceId) return;
    let name = '';
    let find = this.cources.find((x => x.COURSE_ID == this.courceId));
    let controls = this.myForm.controls
    name = find['COURSE_NAME'];
    let aValue = controls['COURSESHD_ACADAMICYEAR'].value;
    if (aValue) {
      let aObj = this.acadamicYears.find((x => x.DICTIONARYID == aValue));
      name = name + '/' + aObj['DICTIONARYNAME'];
      let cyear = controls['COURSESHD_COURSEYEAR'].value;//note change area
      if (cyear) {
        let areaName;
        if(cyear==1){
          areaName='Scholastic';
        }else if(cyear==2){
          areaName='co-scholastic'
        }
        name = name + '/' + areaName;
        let sem = controls['COURSESHD_SEMESTER'].value;
        if (sem) {
          let aObj = this.semesters.find((x => x.DICTIONARYID == sem));
          name = name + '/' + aObj['DICTIONARYNAME'];
          let sub = controls['COURSESHD_SUBJECT'].value;
          if (sub) {
            let aObj = this.subjects.find((x => x.SUBJECT_ID == sub));
            name = name + '/' + aObj['SUBJECT_NAME'];
            let sec = controls['COURSESHD_SECTION'].value;
            if (sec) {
              let aObj = this.sections.find((x => x.DICTIONARYID == sec));
              name = name + '/' + aObj['DICTIONARYNAME'];
            }
          }
        }
      }
    }
    controls['COURSESHD_NAME'].setValue(name)
  }


 getSubjectsbyCourse(){
   let payLoad={
    COURSE_ID:this.courceId,
    TNT_CODE:localStorage.getItem('TenantCode')
   }
   this.activeSpinner();
   this.CommonService.postCall('GetSubjects',payLoad).subscribe(
     (res:any)=>{
       this.subjects=res;
       this.deactivateSpinner();
     },err=>{
       this.deactivateSpinner();
     }
   )
 } 
  getEndDateByCourseId() {
    //GetEndDateByCourseId
    let payload = {
      COURSE_ID: this.courceId,
      START_DATE: this.myForm.controls['COURSESHD_STARTDATE'].value
    }
    this.activeSpinner()
    this.CommonService.postCall('GetEndDateByCourseId', payload).subscribe(
      (res: any) => {
        this.deactivateSpinner();
        if (res) {
          // this.COURSE_DURATION=res[0].COURSE_DURATION;
          this.myForm.controls['COURSESHD_NOOFDAYS'].setValue(this.COURSE_DURATION)
          this.myForm.controls['COURSESHD_ENDDATE'].setValue(moment(res).format('yyyy-MM-DD'))
        }
      }, e => { this.deactivateSpinner() })
  }
  close(){
    window.history.back()
  }
}
