import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { dataDictionary } from 'src/app/dataDictionary';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  index: number;
  locationTypes: Array<any> = [];
  locations: Array<any> = [];
  empTypes: Array<any> = [];
  projectData: Array<any> = [];
  teamSize:Array<number>=Array(30).fill(0).map((x,i)=>i+1);
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      ClientName: ['', Validators.required],
      ProjectTitle: ['', Validators.required],
      DurationFrom: ['', Validators.required],
      DurationTo: ['', Validators.required],
      ProjectLocationType: ['', Validators.required],
      ProjectLocation: ['', Validators.required],
      EmploymentType: ['', Validators.required],
      ProjectDetails: [''],
      Role: [''],
      RoleDescription: [''],
      TeamSize: [''],
      SkillsUsed: [''],
    });
    this.getAll();
    this.parent.childs['ProjectsList'] = this
  }
  ngOnChanges() {
    if (Object.keys(this.editData).length && !this.changed) {
      this.changed = true;
      this.projectData = this.editData['Projects'].slice(0);
      this.dataTransform(this.editData['Projects'].slice(0));
    }
  }
  dataTransform(data: Array<any>) {
    // this.table = data
    data.map((item) => {
      let obj = {
        ProjectID: item['ProjectID'],
        type: item['type'],
        ClientName: item['ClientName'],
        ProjectTitle: item['ProjectTitle'],
        DurationFrom: item['DurationFrom'] ? moment(item['DurationFrom']).format('yyyy-MM-DD') : '',
        DurationTo: item['DurationTo'] ? moment(item['DurationTo']).format('yyyy-MM-DD') : '',
        ProjectLocationType: item['ProjectLocationType'],
       // ProjectLocationTypeName: item['ProjectLocationType'],
        //ProjectLocationName: item['ProjectLocation'],
        ProjectLocation:item['ProjectLocation'],
        //EmploymentTypeName: item['EmploymentType'],
        EmploymentType:item['EmploymentType'],
        ProjectDetails: item['ProjectDetails'],
        Role: item['Role'],
        RoleDescription: item['RoleDescription'],
        TeamSize: item['TeamSize'],
      }
      this.table.push(obj)
      console.log('project',obj);
    })


  }
  getAll() {
    let locationType = this.parent.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.ProjectLocationType});//GetProjectLocationType
    let location = this.parent.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.ProjectLocation});//GetProjectLocation
    let empType = this.parent.CommonService.postCall('GetDictionaryByKey', {DictionaryCode:dataDictionary.EmploymentType});//GetEmployementType
    forkJoin([locationType, location, empType]).subscribe((res) => {
      [this.locationTypes, this.locations, this.empTypes] = [...res];
    })
  }

  add() {
    this.isEdit = false;
    this.setDefaults()
  }
  setDefaults() {
    let ctrls = this.myForm.controls;
    ctrls['ProjectLocationType'].setValue('');
    ctrls['ProjectLocation'].setValue('');
    ctrls['EmploymentType'].setValue('');
    ctrls['TeamSize'].setValue('');
  }
  edit(data, i) {
    this.index = i;
    this.isEdit = true;
    this.editData = data;
    this.assignDataForm(i);
  }
  close() {
    this.myForm.reset()
  }
  onSubmit(form: FormGroup) {
    let value = form.value;
    if (this.isEdit) {
      if (this.editData.type == 'edit') {
        value.type = 'update'
      } else {
        value.type = 'insert'
      }
      value['ProjectID'] = this.editData['ProjectID']
      let data = Object.assign({}, value);
      this.createAndUpdate(data, false)
    } else {
      value.type = 'insert'
      let data = Object.assign({}, value);
      this.createAndUpdate(data, true)
    }
    document.getElementById('md_close_project').click();
  }

  assignDataForm(i) {
    let data = this.projectData[i];
    let ctrls = this.myForm.controls;
    Object.keys(ctrls).map((formControlName: string) => {
      let control: AbstractControl = ctrls[formControlName];
      control.setValue(data[formControlName]);
    })
  }
  check(item) {
    if (item.type == 'delete') {
      return true
    }
    else {
      return false
    }
  }

  createAndUpdate(data, type) {
    let i = this.index;
    // let lTypeIndex = this.locationTypes.findIndex(x => x.DICTIONARYID == data.ProjectLocationType);
    // if (lTypeIndex > -1) {
    //   data.ProjectLocationTypeName = this.locationTypes[lTypeIndex]['DICTIONARYNAME']
    // }
    // let locationIndex = this.locations.findIndex(x => x.DICTIONARYID == data.ProjectLocation);
    // if (locationIndex > -1) {
    //   data.ProjectLocationName = this.locations[locationIndex]['DICTIONARYNAME']
    // }
    // let empIndex = this.empTypes.findIndex(x => x.DICTIONARYID == data.EmploymentType);
    // if (empIndex > -1) {
    //   data.EmploymentTypeName = this.empTypes[empIndex]['DICTIONARYNAME']
    // }
    if (type) {
      this.table.push(data);
      let obj = Object.assign({}, data);
      this.projectData.push(obj);
      this.parent.toastr.success('Added successfully')
    } else {
      this.table[i] = data;
      let obj = Object.assign({}, data);
      this.projectData[i] = obj
      this.parent.toastr.success('Updated successfully')
    }
  }
}
