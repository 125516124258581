<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Role</h5>
                        </div>
                        <div class="card-body">
                            <div class="text-right">
                                <button type="button" class="btn btn-primary mb-1" data-toggle="modal"
                                    data-target="#myModal" (click)="add()">Add</button>
                            </div>
                            <div class="form-group ">
                                <div class="row">
                                    <div *ngIf='isSuperAdmin' class="col-md-3">
                                        <label for="sel1">Tenant Name : </label>
                                        <select [(ngModel)]='tId ' (change)="changeTname()"
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                {{item.TNT_NAME}}</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div >
                                <table datatable id="DataTables_Table_0" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small" style="width:100%">
                                    <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                        <tr>
                                            <th> Role Name </th>
                                            <th>
                                                Status 
                                            </th>
                                            
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table">
                                            <td>{{item.ROLE_NAME}}</td>
                                            <td>{{item.ROLE_STATUS}}</td>
                                        
                                            <td class=" text-center"><input type="image" data-toggle="modal"
                                                    data-target="#myModal"
                                                    src="./../../../assets/images/sprites/edit.svg"
                                                    class="edit_icon text-center" (click)="edit(item)"> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-center" *ngIf="false">
                                No Records to display
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 *ngIf="!isEdit" class="modal-title"> Add Role</h4>
                <h4 *ngIf="isEdit" class="modal-title"> Edit Role</h4>
                <button type="button" (click)="close()" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Role Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input  [attr.disabled]="isEdit ? '' : null"type="text" placeholder="" class="form-control" formControlName="RoleName" maxlength="50">
                                </div>
                                <div *ngIf="myForm.get('RoleName').touched">
                                    <span class="text-danger" *ngIf="myForm.get('RoleName').hasError('required')">
                                        This field is required.
                                    </span>
                                    <span class="text-danger" *ngIf="myForm.get('RoleName').hasError('minlength')">
                                        minimum Length is 3 characters
                                    </span>
                                </div>
                            </div>

                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Decriptions <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea type="text"
                                       
                                        placeholder="" class="form-control" formControlName="Description"></textarea>
                                </div>
                                <div *ngIf="myForm.get('Description').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('Description').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>

                        </div>

                        
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Status <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                        formControlName="Status">
                                        <option value=1>Active</option>
                                        <option value=0>InActive </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-success" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger" id="md_close" (click)="close()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>