import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl,FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { dataDictionary } from 'src/app/dataDictionary';
import { EditUserRegistrationComponent } from 'src/app/pages/edit-user-registration/edit-user-registration.component';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-previous-school-reference',
  templateUrl: './previous-school-reference.component.html',
  styleUrls: ['./previous-school-reference.component.css']
})
export class PreviousSchoolReferenceComponent extends BaseComponent implements OnInit {

  @Input() parent: EditUserRegistrationComponent;
  @Input() editData: any = {};
  changed: boolean = false;
  table: Array<any> = [];
  myForm: FormGroup;
  isEdit: boolean = false;
  index: number;
  empStatus: Array<any> = [];
  PreviousSchoolRefData: Array<any> = []
  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      StudentName: ['', Validators.required],
      PreviousSchoolName: ['', Validators.required],
      Grade: ['', Validators.required],
      DurationFrom: ['', Validators.required],
      DurationTo: ['', Validators.required],
      PassMarkPer: ['', Validators.required],
      TransferCertificate: ['', Validators.required],
  });
    // this.getAll();
    this.parent.childs['PreviousSchoolRefList'] = this;
    
  }
  ngOnChanges() {
    if (Object.keys(this.editData).length && !this.changed) {
      this.changed = true;
      this.PreviousSchoolRefData = this.editData['PreviousSchool'].slice(0);
      this.dataTransform(this.editData['PreviousSchool'].slice(0));
    }
  }
  
  dataTransform(data: Array<any>) {
    data.map(item => {
      let obj = {
        type: item['type'],
        // UserPrevSchoolID: item['UserPrevSchoolID'],
        StudentName: item['StudentName'],
        PreviousSchoolName: item['PreviousSchoolName'],
        Grade: item['Grade'],
        DurationFrom: item['DurationFrom'] ? moment(item['DurationFrom']).format('yyyy-MM-DD') : '',
        DurationTo: item['DurationTo'] ? moment(item['DurationTo']).format('yyyy-MM-DD') : '',
        PassMarkPer: item['PassMarkPer'],
        TransferCertificate: item['TransferCertificate'],
      }
       this.table.push(obj);
    })
    console.log(this.table)
  }
  
  add() {
    this.isEdit = false;
  }
  edit(data, i) {
    console.log(data)
    this.index = i;
    this.isEdit = true;
    this.editData = data;
    this.assignDataForm(i);
  }
  assignDataForm(i){
    let data=this.PreviousSchoolRefData[i];
    let ctrls=this.myForm.controls;
    Object.keys(ctrls).map((formControlName:string)=>{
      let control:AbstractControl=ctrls[formControlName];
      control.setValue(data[formControlName]);
    })
  }
  close() {
    this.myForm.reset()
  }
  onSubmit(form: FormGroup) {
    let value = form.value;
    if (this.isEdit) {
      if (this.editData.TYPE == 'edit') {
        value.TYPE = 'update'
      } else {
        value.TYPE = 'insert'
      }
      // value['UserPrevSchoolID'] = this.editData['UserPrevSchoolID']
      let data = Object.assign({}, value);
      this.createAndUpdate(data, false);
      this.PreviousSchoolRefData.push(data);
    } else {
      value.TYPE = 'insert'
      let data = Object.assign({}, value);
      this.createAndUpdate(data, true);
      this.PreviousSchoolRefData.push(data);
    }
    document.getElementById('md_close_Prev').click();
  }
  
    createAndUpdate(data, type) {
      let i = this.index;
    if (type) {
      this.table.push(data);
      let obj = Object.assign({}, data);
      this.PreviousSchoolRefData.push(obj);
      this.parent.toastr.success('Added successfully')
    } else {
      this.table[i] = data;
      let obj = Object.assign({}, data);
      this.PreviousSchoolRefData[i] = obj
      this.parent.toastr.success('Updated successfully')
    }
  }
}
