
<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Fee Receivables </h5>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="sel1">Academic Year <span class="text-danger">*</span></label>
                                        <select class="form-control form-control-sm shadow-sm rounded-lg"
                                            [disabled]="isParam" (change)="courceChange($event)"
                                            [(ngModel)]='academicId'>
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let academic of academics" [value]='academic.ACADEMIC_ID'>
                                                {{academic.ACADEMIC_NAME}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">Curriculum Name<span class="text-danger">*</span></label>
                                        <select class="form-control form-control-sm shadow-sm rounded-lg"
                                            [disabled]="isParam" (change)="Change($event)" [(ngModel)]='curriculumId'>
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let Curriculum of Curriculums"
                                                [value]='Curriculum.CURRICULUM_ID'>
                                                {{Curriculum.CURRICULUM_NAME}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">User Name <span class="text-danger">*</span></label>
                                        <select class="form-control form-control-sm shadow-sm rounded-lg"
                                            [disabled]="isParam" (change)="load($event)">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let user of users" [value]='user.USERID'>
                                                {{user.USERNAME}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="table.length">
                                <table id="patreggraph" datatable
                                    class="table table-striped table-bordered mt-2 pt-2 table-sm small"
                                    style="width:100%">
                                    <thead class="top bg-info">
                                        <tr>
                                            <th> User Name</th>
                                            <th>Fee Type</th>
                                            <th>curriculum</th>
                                            <th>Course</th>
                                            <th>Term</th>
                                            <th>Total Fee Amount</th>
                                            <th>Paid Amount</th>
                                            <th>Balance Amount</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table">

                                            <td>{{item.STUDENTNAME}}</td>
                                            <td>{{item.FEETYPE_NAME}}</td>
                                            <td>{{item.CURRICULUM_NAME}}</td>
                                            <td>{{item.COURSE_NAME}}</td>
                                            <td> {{item.TERMNAME}}</td>
                                            <td>{{item.FEERECEIVABLE_ACTUALAMOUNT}}</td>
                                          
                                            <td>
                                                <label>
                                                    <input type="number" placeholder=""
                                                        [(ngModel)]="item.FEERECEIVABLE_PAIDAMOUNT"  #amount="ngModel"
                                                        class="form-control">
                                                </label>
                                            </td>

                                            <td>
                                                   {{item.FEEBALANCE}}
                                            </td>
                                          
                                    </tbody>
                                </table>
                                <div class="text-center">
                                    <button id='update' type="button" class="btn btn-success" (click)="Update()">Update</button>
                                </div>
                            </div>
                            <div class="text-center" *ngIf="!table.length">No Records to display</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>