<div class="default_class">
    <div class="content-wrapper p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card rounded shadow-lg">
                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                            <h5 class="mb-0 text-center">Student Information</h5>
                        </div>
                        <div class="card-body">
                            <div class="form-group ">
                                <div class="row">
                                    <div class="col-md-3" *ngIf='roleId=="4"'>
                                        <label for="sel1">Tenant Name : </label>
                                        <select [(ngModel)]='tId ' (change)="changeTname()"
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                {{item.TNT_NAME}}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">Course Name : </label>
                                        <select [(ngModel)]='courseId' (change)="changeCourse()"
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                                {{course.COURSE_NAME}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="sel1">	Batch  : </label>
                                        <select [(ngModel)]='scheduleId'
                                            class="form-control form-control-sm shadow-sm rounded-lg" id="sel1">
                                            <option value="" [selected]="true">select</option>
                                            <option *ngFor="let schedule of schedules"
                                            [value]='schedule.COURSESHD_ID'>{{schedule.COURSESHD_NAME}}
                                        </option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label ></label>
                                        <button class="btn btn-success" [disabled]='!courseId ||!scheduleId' (click)='submit()'>submit </button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="table.length">
                                <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                    style="width:100%">
                                    <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                        <tr>
                                            <th>S.No</th>
                                            <th>
                                                Course
                                            </th>
                                            <th>Full Name</th>
                                            <th>Email</th>
                                            <th>Mobile No</th>
                                            <th>Gender</th>
                                            <th>Status</th>
                                            <th>Age</th>
                                             <th>	  Registered On</th> 
                                                <th>Last Login On </th>
                                           <th>Enrolled On</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of table;let i=index">
                                            <td>{{i+1}}</td>
                                               <td>{{item.COURSE_NAME}}</td>
                                               <td>{{item.FULLNAME}}</td>
                                               <td>{{item.EMAILID}}</td>
                                               <td>{{item.MOBILENO}}</td>
                                               <td>{{item.DICTIONARYNAME}}</td>
                                               <td>{{item.STATUSS}}</td>
                                               <td>{{item.AGE}}</td>
                                               <td>{{item.CREATEDON}}</td>
                                               <td>{{item.MAXDATE}}</td>
                                               <td>{{item.CREATEDON}}</td>
                                               </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>